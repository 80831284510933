import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { loginUser, removeAuthErrors } from '../../../redux/actions/AuthActions'
import { HOME, PASSWORD_FORGET } from '../../../constants/Routes'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

import Surfer from '../../../assets/images/landing-page/surfer.png'

const SignInModal = ({ setIsSignUpOpen, setIsSignInOpen }) => {
  return (
    <section className="tikbox-signup">
      <div className="darkBG" onClick={() => setIsSignInOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="surfer-image">
            <img src={Surfer} alt="" />
          </div>
          <div className="form">
            <SignInForm />
            <div className="hint">
              Don’t you have an account?{' '}
              <button
                className="link"
                onClick={() => {
                  setIsSignInOpen(false)
                  setIsSignUpOpen(true)
                }}>
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: {},
    }
  }

  componentDidMount() {
    const { auth } = this.props
    const params = new URLSearchParams(this.props.location.search)
    let redirect = params.get('redirect')
    let ref = params.get('verify_ref')
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (auth?.isAuthenticated) {
      this.props.history.push(
        `${redirect ? `${redirect}?verify_ref=${ref}` : HOME}`,
      )
    }
  }

  componentDidUpdate() {
    const loginError = this.props.auth.loginError
    if (loginError) {
      toast.dismiss()
      toast.error(loginError)

      this.props.removeAuthErrors()
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { auth } = nextProps
    if (auth?.isAuthenticated) {
      const params = new URLSearchParams(nextProps.location.search)
      let redirect = params.get('redirect')
      let ref = params.get('verify_ref')
      nextProps.history.push(
        `${redirect ? `${redirect}?verify_ref=${ref}` : HOME}`,
      )
    }

    return null
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('login', { method: 'Form' })

    const userData = {
      email: this.state.email,
      password: this.state.password,
    }

    this.props.loginUser(userData)
  }

  render() {
    const { email, password, errors } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <h1>
          Sign in to <b>TikBox</b>
        </h1>
        <p>Username</p>
        <input
          id="username"
          type="email"
          value={email}
          error={errors.email}
          required
          name="email"
          onChange={this.onChange}
          placeholder="Email Address"
        />
        <p>Password</p>
        <input
          required
          id="password"
          type="password"
          name="password"
          placeholder="password"
          value={password}
          error={errors.password}
          onChange={this.onChange}
        />
        <button
          className={
            isInvalid || this.props.auth.signInLoading
              ? 'submit-disabled '
              : 'submit-enabled '
          }
          disabled={isInvalid || this.props.auth.signInLoading}
          type="submit">
          {this.props.auth.signInLoading ? 'Signing in...' : 'Sign In'}
        </button>
        <div className="hint" style={{ marginBottom: 20 }}>
          <Link className="link" to={PASSWORD_FORGET}>
            Forgot Password?
          </Link>
        </div>
      </form>
    )
  }
}

// eslint-disable-next-line no-lone-blocks
{
  /* <PasswordForgetLink /> */
}
SignInFormBase.propTypes = {
  loginUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const SignInForm = connect(mapStateToProps, { loginUser, removeAuthErrors })(
  withRouter(SignInFormBase),
)

export default SignInModal
