import { useEffect, useRef, useState } from 'react'
import PlayBtn from '../../../assets/images/landing-page/play_button.png'
import PauseBtn from '../../../assets/images/landing-page/pause_button.png'
//import BannerImg from '../../../assets/images/landing-page/banner_image.jpg'
import BannerVideo from '../../../assets/video/banner_tikbox.mp4'
const videoDuration = 11

const Banner = ({ setIsSignUpOpen }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const videoRef = useRef(null)
  const playRef = useRef(null)
  let videoTimerRef = useRef(null)

  const togglePlay = () => {
    if (videoRef?.current?.paused) {
      videoRef.current
        .play()
        .then(() => {
          setIsVideoPlaying(true)
          videoTimerRef.current = setTimeout(() => {
            setIsVideoPlaying(false)
            videoRef?.current?.pause()
            if (videoRef && videoRef?.current & videoRef.current.currentTime) {
              videoRef.current.currentTime = 1
            }
          }, (videoDuration - videoRef?.current?.currentTime) * 1000)
        })
        .catch((error) => {
          console.error('Error playing video:', error)
        })
    } else {
      videoRef?.current?.pause()
      setIsVideoPlaying(false)
      clearTimeout(videoTimerRef.current)
    }
  }

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.muted = true // Ensure the video is muted for autoplay
      togglePlay()
    }
    return () => {
      if (!videoRef?.current?.paused) {
        togglePlay()
        clearTimeout(videoTimerRef.current)
      }
    }
  }, [])

  return (
    <section className="tikbox-banner">
      <div className="tikbox-row">
        <div className="tikbox-col-4">
          <div className="tikbox-content">
            <h1>Adapt, secure, thrive </h1>
            <p>
              Transforming how digital content is secured, shared, and monetized
            </p>
            <button onClick={() => setIsSignUpOpen(true)}>
              START FREE TRIAL
            </button>
          </div>
        </div>
        <div className="tikbox-col-6">
          <div className="tikbox-content">
            <div className="video-content animate__animated animate__fadeIn">
              {/* <img
                src={BannerImg}
                alt="banner"
                className="tikbox-banner-img"
                style={{ maxHeight: window.innerWidth > 1000 ? 500 : 350 }}
              /> */}
              {
                <video
                  onClick={togglePlay}
                  ref={videoRef}
                  id="video1"
                  width="540"
                  height="427"
                  muted={false}
                  // controls
                >
                  <source src={BannerVideo} type="video/mp4" />
                  <source src={BannerVideo} type="video/ogg" />
                  Your browser does not support HTML video.
                </video>
              }
              {isVideoPlaying ? (
                <img
                  onClick={togglePlay}
                  className="toggle-play"
                  src={PauseBtn}
                  alt="toggle-btn"
                />
              ) : (
                <img
                  ref={playRef}
                  onClick={togglePlay}
                  className="toggle-play"
                  src={PlayBtn}
                  alt="toggle-btn"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
