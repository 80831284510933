import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { HOME } from '../../../constants/Routes'
import AssignementContent from './AssignmentContent'
import LicenseContent from './LicenseContent'
import NavigationBtns from './NavigationBtns'
import AgreementHeader from './AgreementHeader'
import RoyaltyContent from './RoyaltyContent'
import BoilerplateContent from './BoilerplateContent'
import {
  getAgreements,
  updateAgreement,
} from '../../../redux/actions/AgreementActions'
import Spinner from '../../../components/common/spinner/Spinner'
import { AgreementCategory, AgreementKeys } from '../../../constants'
import ReviewContent from './ReviewContent'
import { saveUserAgreement } from '../../../redux/actions/UserAgreementActions'
import { toast } from 'react-toastify'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const emptyAgreement = {
  [AgreementKeys.Assignment]: [],
  [AgreementKeys.License]: [],
  [AgreementKeys.RoyaltyValue]: 0,
  [AgreementKeys.RoyaltyLength]: undefined,
  [AgreementKeys.RoyaltyAcknowledgement]: '',
}

const AgreementBody = ({
  step,
  addAgreement,
  selectedAgreements,
  removeAgreement,
  acceptedTerms,
  acceptAgreementTerms,
  reviewRef,
}) => {
  const {
    agreements: { data: agreementData },
    auth: { user },
    assets: { signing },
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [editingAgreement, setEditingAgreement] = useState()
  const [agreementContent, setAgreementContent] = useState()

  const setEditAgreement = (id, content) => {
    setEditingAgreement(id)
    setAgreementContent(content)
  }

  const cancelEditAgreement = () => {
    setEditingAgreement()
    setAgreementContent()
  }

  const saveUpdatedAgreement = async () => {
    if (editingAgreement) {
      dispatch(updateAgreement(editingAgreement, agreementContent))
      cancelEditAgreement()
    }
  }

  if (!agreementData) return <div>Retrieving data...</div>

  return (
    <div className="agreement-interface-body">
      <div className="agreement-interface-body__content">
        {step === 1 ? (
          <AssignementContent
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) =>
                agreement.category === AgreementCategory.ASSIGNMENT,
            )}
          />
        ) : step === 2 ? (
          <LicenseContent
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) => agreement.category === AgreementCategory.LICENSE,
            )}
          />
        ) : step === 3 ? (
          <RoyaltyContent
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) => agreement.category === AgreementCategory.ROYALTY,
            )}
          />
        ) : step === 4 ? (
          <BoilerplateContent
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) =>
                agreement.category === AgreementCategory.BOILERPLATE,
            )}
          />
        ) : (
          <ReviewContent
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            reviewRef={reviewRef}
            selectedAgreements={selectedAgreements}
            agreementData={agreementData}
            user={user}
            signingAssets={signing}
            acceptedTerms={acceptedTerms}
            acceptAgreementTerms={acceptAgreementTerms}
            data={agreementData.filter(
              (agreement) =>
                agreement.category === AgreementCategory.BOILERPLATE,
            )}
          />
        )}
      </div>
    </div>
  )
}

const Agreement = () => {
  const history = useHistory()
  const location = useLocation()
  const reviewRef = useRef()
  const dispatch = useDispatch()
  const { template, copyright, editAsset } = location.state || {}

  const {
    agreements: { data, loading },
    assets,
    agreementTemplates: { data: agreementTemplatesData },
    userAgreements,
    auth: { user },
  } = useSelector((state) => state)

  const [step, setStep] = useState(1)
  const [selectedAgreements, setSelectedAgreements] = useState(
    _.cloneDeep(emptyAgreement),
  )
  const [acceptedTerms, setAcceptedTerms] = useState({
    agree1: false,
    agree2: false,
  })
  const [downloading, setDownloading] = useState(false)

  const nextStep = () => {
    if (step < 5) {
      setStep((prevState) => prevState + 1)
    }
  }
  const prevStep = () => {
    if (step === 1) {
      history.push(HOME)
    }
    setStep((prevState) => prevState - 1)
  }

  const acceptAgreementTerms = (e) => {
    setAcceptedTerms({ ...acceptedTerms, [e.target.name]: e.target.checked })
  }

  const addAgreement = (key, value) => {
    const existingAgreement = selectedAgreements[key]
    if (
      [
        AgreementKeys.RoyaltyLength,
        AgreementKeys.RoyaltyValue,
        AgreementKeys.RoyaltyAcknowledgement,
      ].includes(key)
    ) {
      setSelectedAgreements({
        ...selectedAgreements,
        [key]: value,
      })
    } else {
      const existingIndex = existingAgreement.findIndex(
        (entry) => entry.index === value,
      )
      if (existingIndex >= 0) {
        existingAgreement.splice(existingIndex, 1, { index: value })
      } else {
        existingAgreement.push({ index: value })
      }
      setSelectedAgreements({
        ...selectedAgreements,
        [key]: existingAgreement,
      })
    }
  }
  const removeAgreement = (key, value) => {
    const existingAgreement = selectedAgreements[key]
    if (
      ![
        AgreementKeys.RoyaltyLength,
        AgreementKeys.RoyaltyValue,
        AgreementKeys.RoyaltyAcknowledgement,
      ].includes(key)
    ) {
      const existingIndex = existingAgreement.findIndex(
        (entry) => entry.index === value,
      )
      if (existingIndex >= 0) {
        existingAgreement.splice(existingIndex, 1)
        setSelectedAgreements({
          ...selectedAgreements,
          [key]: existingAgreement,
        })
      }
    }
  }

  const uploadAgreement = async (isDraft = false) => {
    if (!isDraft && !assets.signing.length) {
      toast.error('Upload an asset')
      return 'error'
    }
    if (assets.signing) {
      dispatch(
        saveUserAgreement({
          ...selectedAgreements,
          template,
          copyright,
          isDraft,
          assets: assets.signing.map(({ _id }) => _id),
        }),
      )
    }
  }

  const closeAgreement = async () => {
    // TODO: Save draft agreement
    await uploadAgreement(true)
    setSelectedAgreements(_.cloneDeep(emptyAgreement))
    history.goBack()
  }

  const handleGetAgreements = async () => {
    await dispatch(getAgreements())
  }

  const downloadAgreement = async () => {
    setDownloading(true)
    triggerGoogleAnalyticsEvent('downloading_agreement', {
      userId: user?.id,
    })
    setTimeout(async () => {
      const response = await uploadAgreement(false)
      if (response !== 'error') {
        setDownloading(false)
        setSelectedAgreements(_.cloneDeep(emptyAgreement))
        history.push(HOME)
        toast.success('Agreement is downloading...')
      }
    }, 2000)
  }

  useEffect(() => {
    handleGetAgreements()
  }, [])

  useEffect(() => {
    if (template) {
      const agreementTemplate = agreementTemplatesData.find(
        (agreement) => agreement._id === template,
      )
      if (agreementTemplate) {
        setSelectedAgreements(agreementTemplate)
      }
    }
    if (editAsset) {
      const userAgreement = userAgreements.data.find(
        (x) => x.assetId === editAsset._id,
      )
      if (userAgreement) {
        setSelectedAgreements(userAgreement)
      }
    }
  }, [template, editAsset])

  return (
    <div className="agreement">
      <AgreementHeader closeAgreement={closeAgreement} step={step} />
      {loading && !data ? (
        <Spinner />
      ) : (
        <div className="agreement-interface">
          <AgreementBody
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            step={step}
            acceptedTerms={acceptedTerms}
            acceptAgreementTerms={acceptAgreementTerms}
            reviewRef={reviewRef}
          />
          <NavigationBtns
            acceptedTerms={acceptedTerms}
            step={step}
            prevStep={prevStep}
            nextStep={nextStep}
            downloadAgreement={downloadAgreement}
            downloading={downloading}
          />
        </div>
      )}
    </div>
  )
}

export default Agreement
