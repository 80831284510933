import { useState } from 'react'
import Header from '../../components/header/Header'
import { Footer } from '../../components/footer/Footer'
import SignUpModal from '../../pages/main/landing-page/SignUp'
import SignInModal from '../../pages/main/landing-page/SignIn'
const PLANS = [
  {
    title: 'Free',
    subtitle: 'For newly minted creatives, or those on a small budget',
    priceYearly: 'Free',
    priceMonthly: 'Free',
    cummPrice: '',
    brief2: '+15-30% per licensing transaction',
    featuresTitle: 'Core features include',
    features: [
      '10 assets/month',
      'Licensing templates',
      'Provenance labelling',
      'Double encryption',
      //  'Creative Commons',
      'Visible watermarking',
      'Legally compliant e-signature',
      'Direct royalty payment',
    ],
  },
  {
    title: 'Solo Creator',
    subtitle:
      'For individuals serious about licensing revenue and provenance labelling',
    priceYearly: '£4.50',
    priceMonthly: '£7.00',
    cummPrice: '£54',
    brief2: '+2% per licensing transaction if applicable',
    featuresTitle: 'Core features include',
    features: [
      '15 assets/month',
      'Licensing templates',
      'Provenance labelling',
      'Double encryption',
      //  'Creative Commons',
      'Visible watermarking',
      'Legally compliant e-signature',
      'Direct royalty payment',
    ],
  },
  {
    title: 'Business',
    subtitle:
      'Ideal for small teams requiring custom branding, licensing, and provenance labelling for large volumes of assets',
    priceYearly: '£20',
    priceMonthly: '£30',
    cummPrice: '£240',
    brief2: '+2% per licensing transaction if applicable',
    featuresTitle: 'Core features include',
    features: [
      '15 assets/month/user',
      'Licensing templates',
      'Provenance labelling',
      'Double encryption',
      //  'Creative Commons',
      'Visible watermarking',
      'Legally compliant e-signature',
      'Direct royalty payment',
      'Customised branding',
      'Legal/agreement admin',
      'Bulk agreements',
      'Legally compliant e-signature',
      'Direct royalty payment',
    ],
    popular: true,
  },
  {
    title: 'Business Pro',
    subtitle:
      'Professional organisations requiring custom branding, licensing, and provenance labelling for large volumes of assets',
    priceYearly: '£41.50',
    priceMonthly: '£60',
    cummPrice: '£498',
    brief2: '',
    featuresTitle: 'Core features include',
    features: [
      '20 assets/month/user',
      'Licensing templates',
      'Provenance labelling',
      'Double encryption',
      //  'Creative Commons',
      'Visible watermarking',
      'Legally compliant e-signature',
      'Direct royalty payment',
      'Customised branding',
      'Legal/agreement admin',
      'Bulk agreements',
      'Legally compliant e-signature',
      'Direct royalty payment',
      'No licensing fee',
    ],
  },
  {
    title: 'Enterprise',
    subtitle:
      'Large organisations requiring custom branding, licensing, and provenance labelling for large volumes of assets, Plus customer support.',
    priceYearly: '£500',
    priceMonthly: null,
    cummPrice: '£6000',
    brief2: '',
    featuresTitle: 'Core features include',
    features: [
      '50 assets/month/user',
      'Licensing templates',
      'Provenance labelling',
      'Double encryption',
      //  'Creative Commons',
      'Visible watermarking',
      'Legally compliant e-signature',
      'Direct royalty payment',
      'Customised branding',
      'Legal/agreement admin',
      'Bulk agreements',
      'Legally compliant e-signature',
      'Direct royalty payment',
      'No licensing fee',
      '24/7 support',
      'Single sign-on (SSO)',
      'Service level agreement',
      'Custom integration available',
    ],
  },
]

const PricingPlan = ({
  title,
  subtitle,
  price,
  cummPrice,
  brief2,
  featuresTitle,
  features,
  popular = false,
}) => {
  return (
    <div className={`pricing-plan${popular ? ' popular' : ''}`}>
      <div className="pricing-plan__top">
        {popular && <h4 className="pricing-plan__highlight">MOST POPULAR</h4>}
        <h3 className="pricing-plan__title">{title}</h3>
        <p className="pricing-plan__subtitle">{subtitle}</p>
        {price === 'Free' ? (
          <h4 className="pricing-plan__price">{price}</h4>
        ) : price ? (
          <h4 className="pricing-plan__price">
            {price}/
            <span className="pricing-plan__price-sub">month per user</span>
          </h4>
        ) : null}
        <p className="pricing-plan__price-brief1">
          {cummPrice ? `${cummPrice} billed annually` : ''}
        </p>
        <p className="pricing-plan__price-brief2">{brief2}</p>
      </div>
      <button className="pricing-plan__buy-btn" disabled={!price}>
        Start Now
      </button>
      <p className="pricing-plan__details-title">{featuresTitle}</p>
      <ul className="pricing-plan__details">
        {features.map((feature) => (
          <li>
            <b>&#10003;</b> <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

const PricingPage = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  const [durationActive, setDurationActive] = useState(1)
  const [activeTab, setActiveTab] = useState(1)
  return (
    <>
      <Header
        setIsSignUpOpen={setIsSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
      <div className="pricing">
        <div className="pricing-container">
          <div className="pricing-title">
            <h2>Choose your plan</h2>
          </div>
          <div className="pricing-tabs">
            <div className="pricing-tabs-categories">
              <div
                className={`pricing-tab-category${
                  activeTab === 1 ? ' active' : ''
                }`}
                onClick={() => setActiveTab(1)}>
                <p>Creators and their agents</p>
              </div>
              <div
                className={`pricing-tab-category${
                  activeTab === 2 ? ' active' : ''
                }`}
                onClick={() => setActiveTab(2)}>
                <p>Developer API</p>
              </div>
            </div>
            <div className="pricing-tabs-duration">
              <div className="pricing-duration-toggle">
                <div className="pricing-duration-toggle__container">
                  <div
                    onClick={() => setDurationActive(1)}
                    className={`pricing-duration-switch left${
                      durationActive === 1 ? ' active' : ''
                    }`}>
                    <span>Annual</span>
                    <span className="pricing-duration-switch__label">
                      Save up to 44%
                    </span>
                  </div>
                  <div
                    onClick={() => setDurationActive(2)}
                    className={`pricing-duration-switch right${
                      durationActive === 2 ? ' active' : ''
                    }`}>
                    <span>Monthly</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {activeTab === 1 && (
            <div className="pricing-plans">
              {PLANS.map((plan) => {
                const price =
                  durationActive === 1 ? plan.priceYearly : plan.priceMonthly
                const cummPrice = durationActive === 1 ? plan.cummPrice : null
                const propsObj = { ...plan, price, cummPrice }
                return <PricingPlan {...propsObj} />
              })}
            </div>
          )}
          {activeTab === 2 && (
            <div className="pricing-api">
              <br />
              <p>
                Due for release soon. Please contact admin (at) tikbox.io with
                your requirements to learn more
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
      <br />
      <Footer />
      {isSignUpOpen && (
        <SignUpModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      {isSignInOpen && (
        <SignInModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
    </>
  )
}

export default PricingPage
