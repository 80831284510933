import {
  SAVE_ASSETS,
  GET_ASSETS,
  GET_ASSETS_LOADING,
  REMOVE_SIGNING_ASSETS,
  RESET_SIGNING_ASSETS,
} from '../actions/Types'

const initialState = {
  data: [],
  signing: [],
  loading: false,
}

export default function AssetReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ASSETS:
      return {
        ...state,
        data: [...state.data, ...action.payload],
        signing: [...state.signing, ...action.payload],
      }
    case RESET_SIGNING_ASSETS: {
      return {
        ...state,
        signing: action.payload,
      }
    }
    case REMOVE_SIGNING_ASSETS: {
      const removeAll = action.payload.removeAll
      const removeName = action.payload.name
      const existingSigningData = state.signing
      const newSigningData = removeAll
        ? []
        : existingSigningData.filter((x) => !x.fileUrl.includes(removeName))
      return {
        ...state,
        signing: newSigningData,
      }
    }
    case GET_ASSETS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_ASSETS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
