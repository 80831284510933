import {
  SAVE_AGREEMENT_TEMPLATE,
  GET_AGREEMENT_TEMPLATES,
  GET_AGREEMENT_TEMPLATES_LOADING,
} from '../../redux/actions/Types'

const initialState = {
  data: [],
  signing: [],
  loading: false,
}

export default function AgreementTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_AGREEMENT_TEMPLATE:
      return {
        ...state,
        data: [...state.data, action.payload],
        signing: action.payload,
      }
    case GET_AGREEMENT_TEMPLATES:
      return {
        ...state,
        data: action.payload,
      }
    case GET_AGREEMENT_TEMPLATES_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
