import './header.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PRICING, HOME, VERIFY } from '../../constants/Routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBahai } from '@fortawesome/free-solid-svg-icons'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { faPhotoVideo } from '@fortawesome/free-solid-svg-icons'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import { faBlog } from '@fortawesome/free-solid-svg-icons'
import MenuToggler from './../../assets/images/landing-page/menu_toggler.png'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import LogoText from './../../assets/images/TikBoxLogoBlue.png'

const ToolsMegaMenu = () => {
  return (
    <div className="mega-menu lv-1">
      <div className="content">
        <div className="primary">
          <div className="sect">
            <ul>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faBahai} />
                </div>
                <div className="text-container">
                  <h3>Copyrights and Licensing</h3>
                  <p>
                    Create licensing agreements and metatags easily with speed
                  </p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <div className="text-container">
                  <h3>Contract Generator</h3>
                  <p>Build custom contracts or use a template</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faPhotoVideo} />
                </div>
                <div className="text-container">
                  <h3>Provenance and Deepfakes</h3>
                  <p>Use C2PA labels to distinguish between real and fake</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faCameraRetro} />
                </div>
                <div className="text-container">
                  <h3>Monatizing Copyrights</h3>
                  <p>Auto-invoice licensees and collect payment</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="secondary">
          <div className="sect">
            <h3>Copyrights and Licensing</h3>
            <ul>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Contract Generator</h3>
            <ul>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Provenance and Deepfakes</h3>
            <ul>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Monatizing Copyrights</h3>
            <ul>
              <li>
                <a href="#"></a>
              </li>
              <li>
                <a href="#"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const UseCasesMegaMenu = () => {
  return (
    <div className="mega-menu lv-2">
      <div className="content">
        <div className="primary">
          <div className="sect">
            <ul>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faBahai} />
                </div>
                <div className="text-container">
                  <h3>Social Media</h3>
                  <p>Get the best out of your digital creations</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <div className="text-container">
                  <h3>Personal Sites</h3>
                  <p>Protect your personal content</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faPhotoVideo} />
                </div>
                <div className="text-container">
                  <h3>Marketing & Brand Management</h3>
                  <p>Don't let deepfakes hijack your brand</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="secondary">
          <div className="sect">
            <h3>Social Media</h3>
            <ul>
              <li>
                <a href="#">Instagram</a>
              </li>
              <li>
                <a href="#">TikTok</a>
              </li>
              <li>
                <a href="#">Twitter</a>
              </li>
              <li>
                <a href="#">YouTube</a>
              </li>
              <li>
                <a href="#">LinkedIn</a>
              </li>
              <li>
                <a href="#">Twitch</a>
              </li>
              <li>
                <a href="#">Facebook</a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Marketing & Brand Management</h3>
            <ul>
              <li>
                <a href="#">...</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Knowledge Based Assets</h3>
            <ul>
              <li>
                <a href="#">Business Insights and Reports</a>
              </li>
              <li>
                <a href="#">Learning and Education</a>
              </li>
              <li>
                <a href="#">Corporate Content</a>
              </li>
              <li>
                <a href="#">...</a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Compliancy</h3>
            <ul>
              <li>
                <a href="#">US Executive Order</a>
              </li>
              <li>
                <a href="#">EU AI Act</a>
              </li>
              <li>
                <a href="">Digital Millennium Copyrights Act (DMCA)</a>
              </li>
              <li>
                <a href="#">General Data Protection Regulations (GDPR)</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const ResourcesMegaMenu = () => {
  return (
    <div className="mega-menu lv-3">
      <div className="content">
        <div className="primary">
          <div className="sect">
            <ul>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faBlog} />
                </div>
                <div className="text-container">
                  <h3>
                    <a
                      href="#/"
                      //target="_blank"
                      rel="noreferrer">
                      Blog
                    </a>
                  </h3>
                  <p>Your guide for creative licensing</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <div className="text-container">
                  <h3>
                    <a
                      href="https://www.youtube.com/tikbox"
                      target="_blank"
                      rel="noreferrer">
                      Video tutorials
                    </a>
                  </h3>
                  <p>How-to video tutorials</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faBahai} />
                </div>
                <div className="text-container">
                  <h3>
                    <a href="#" target="_blank" rel="noreferrer">
                      Trends
                    </a>
                  </h3>
                  <p>Latest in asset licensing</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
const Header = ({ setIsSignUpOpen, setIsSignInOpen }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const toggleMenu = () => {
    setOpenMenu((prevState) => !prevState)
  }
  return (
    <header>
      <div className="tikbox-promo">
        {/*<p>USE THE CODE AHDN30 TO GET $100 OFF</p>*/}
      </div>
      <div className="tikbox-navbar">
        <div className="tikbox-navbar__logo">
          <Link to={HOME} className="tikbox-logo-link" rel="landing">
            <img src={LogoText} className="tikbox-logo" alt="logo" />
          </Link>
        </div>
        <div className="tikbox-navbar__toggler" onClick={toggleMenu}>
          <img src={MenuToggler} alt="menu-toggler" />
        </div>
        <div className={`tikbox-navbar__menu${openMenu ? ' open' : ''}`}>
          <ul className="tikbox-navbar__menu-links">
            <li className="tikbox-navbar__menu-links__link anchor-link has-mega-menu active">
              <a className="title" href="#">
                <span>Tools</span>
                <FontAwesomeIcon
                  className="dropdown-icon"
                  size="lg"
                  icon={faCaretDown}
                />
              </a>
              <ToolsMegaMenu />
            </li>
            {/* <li className="tikbox-navbar__menu-links__link anchor-link has-mega-menu">
              <a className="title" href="#">
                <span>Use Cases</span>
                <FontAwesomeIcon
                  className="dropdown-icon"
                  size="lg"
                  icon={faCaretDown}
                />
              </a>
              <UseCasesMegaMenu />
            </li>
            <li className="tikbox-navbar__menu-links__link anchor-link has-mega-menu mega-menu-relative">
              <a className="title" href="#">
                <span>Resources</span>
                <FontAwesomeIcon
                  className="dropdown-icon"
                  size="lg"
                  icon={faCaretDown}
                />
              </a>
              <ResourcesMegaMenu />
            </li> */}
             <li className="tikbox-navbar__menu-links__link anchor-link">
              <Link to={VERIFY} href="#" className="title" rel="noreferrer">
                Verify
              </Link>
              </li>
            <li className="tikbox-navbar__menu-links__link anchor-link">
              <Link to={PRICING} href="#" className="title" rel="noreferrer">
                Pricing
              </Link>
            </li>
            <li className="tikbox-navbar__menu-links__link link-btn signin-btn">
              <button onClick={() => setIsSignInOpen(true)}>Sign in</button>
            </li>
            <li className="tikbox-navbar__menu-links__link link-btn try-btn">
              <button onClick={() => setIsSignUpOpen(true)}>
                START FREE TRIAL
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
