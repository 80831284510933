export const VideoUploadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4125 20.4643L18.4125 20.4643C17.8994 20.9472 17.2078 21.2174 16.1006 21.3575C14.982 21.4991 13.5168 21.5 11.5 21.5H10.7273C9.08594 21.5 7.8936 21.4994 6.9687 21.403C6.05177 21.3074 5.45789 21.1224 4.99104 20.7935C4.70483 20.5918 4.45176 20.3534 4.23903 20.0859L4.23902 20.0859C3.89586 19.6544 3.70288 19.1079 3.60254 18.2551C3.50081 17.3905 3.5 16.2741 3.5 14.7273V12.1818C3.5 10.6976 3.50008 9.08403 3.55818 7.69434C3.58722 6.99964 3.63048 6.36872 3.6942 5.84295C3.75914 5.30715 3.84133 4.91748 3.93377 4.68421L3.93377 4.68421C4.57466 3.06698 6.25649 2.50001 7.83836 2.5L13.8182 2.5C15.6596 2.5 16.4519 2.50714 17.0696 2.72397L17.0696 2.72398C18.0952 3.084 18.8925 3.84101 19.2672 4.78635L19.2672 4.78637C19.3741 5.05602 19.4357 5.37858 19.4676 5.86167C19.4997 6.34924 19.5 6.96497 19.5 7.81818V14C19.5 15.9006 19.4988 17.2727 19.3494 18.3184C19.2026 19.3465 18.9213 19.9854 18.4125 20.4643Z"
        fill="url(#paint0_linear_2020_4169)"
        stroke="white"
      />
      <path
        d="M14.25 11.067C14.5833 11.2594 14.5833 11.7406 14.25 11.933L10.5 14.0981C10.1667 14.2905 9.75 14.05 9.75 13.6651L9.75 9.33494C9.75 8.95004 10.1667 8.70947 10.5 8.90192L14.25 11.067Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2020_4169"
          x1="11.048"
          y1="3.42492"
          x2="11.048"
          y2="21.9999"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#B0B8FF" />
          <stop offset="1" stop-color="#8D98F8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
