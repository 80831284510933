import { getAssetInMB, roundDown } from '../../../utils/Helper'
import { AssetUploadedIcon } from '../icons/AssetUploadedIcon'
import AssetUploadingIcon from '../../../assets/images/asset-uploading.png'
import { AssetDeleteIcon } from '../icons/DeleteIcon'
import { AssetUploadCancelIcon } from '../icons/AssetUploadCancelIcon'
import { ASSET_UPLOAD_TYPE } from '../../../constants'

const UploadBoxStatus = ({
  uploadAssetType,
  uploadProgress,
  sizeTotal,
  resetAssetUplaoded,
  toggleUploadsModal,
  assetsUploadProgresses,
  showMultipleSummary,
}) => {
  const assetsUploaded = assetsUploadProgresses?.filter(
    (x) => x.progress === 100,
  )
  const noOfAssetsUploaded = assetsUploaded?.length
  const assetsInProgress = assetsUploadProgresses?.filter(
    (x) => x.progress !== 100,
  )
  const noOfAssetsInProgress = assetsInProgress?.length
  const totalAssetsSize = getAssetInMB(
    assetsUploadProgresses?.reduce((acc, data) => {
      return acc + data.file.size
    }, 0),
  )
  return (
    <div className="upload-box-status">
      <div className="progress-details">
        <div className="upload-status">
          {uploadProgress === 100 ? (
            <AssetUploadedIcon />
          ) : (
            <img
              className="uploading-icon"
              src={AssetUploadingIcon}
              alt="uploading"
            />
          )}

          <div className="upload-text">
            <p>
              {uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE &&
              showMultipleSummary &&
              noOfAssetsUploaded > 0
                ? `${noOfAssetsUploaded} file(s) uploaded`
                : 'Contract draft'}
            </p>
            <p>
              {uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE &&
              showMultipleSummary ? (
                <span>
                  {noOfAssetsInProgress > 0
                    ? `${noOfAssetsInProgress} in progress`
                    : `${totalAssetsSize}MB`}
                </span>
              ) : (
                <>
                  <span>
                    {roundDown((uploadProgress / 100) * sizeTotal)}MB/
                    {sizeTotal}MB
                  </span>
                  <span>2mins left</span>{' '}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="upload-action-btn">
          {uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE ? (
            <span className="view-details-btn" onClick={toggleUploadsModal}>
              View
            </span>
          ) : uploadProgress === 100 ? (
            <AssetDeleteIcon onClick={() => resetAssetUplaoded(null)} />
          ) : (
            <AssetUploadCancelIcon onClick={() => resetAssetUplaoded(null)} />
          )}
        </div>
      </div>
      {uploadProgress !== 100 && (
        <div className="progress-bar">
          <div
            className="progress-bar__progression"
            style={{ width: `${uploadProgress}%` }}></div>
        </div>
      )}
    </div>
  )
}

export default UploadBoxStatus
