import instance from '../../utils/Axios'
import setAuthToken from '../../utils/SetAuthToken'
import jwt_decode from 'jwt-decode'
import store from '../index'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

import {
  CLEAR_AUTH_ERRORS,
  GET_SIGNIN_ERRORS,
  GET_SIGNUP_ERRORS,
  SET_CURRENT_USER,
  SIGNIN_LOADING,
  SIGNOUT_USER,
  SIGNUP_LOADING,
} from './Types'
import { toast } from 'react-toastify'

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
    payload: true,
  })
  instance
    .post('/users/register', userData)
    .then((res) => {
      toast.success(
        userData.reason
          ? 'Successful! We will get back to you on this'
          : 'Successful! Please, check your email & confirm your account.',
      )
    })
    .catch((err) => {
      dispatch({
        type: GET_SIGNUP_ERRORS,
        payload: err.response ? err.response.data : err,
      })
    })
    .finally(() => {
      dispatch({
        type: SIGNUP_LOADING,
        payload: false,
      })
    })
}

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  dispatch({
    type: SIGNIN_LOADING,
    payload: true,
  })
  instance
    .post('/users/login', userData)
    .then(async (res) => {
      // Set token to localStorage
      const { token } = res.data
      localStorage.setItem('jwtTokenCTEditor', token)
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      dispatch(setCurrentUser(decoded))
      return { token }
    })
    .catch((err) => {
      dispatch({
        type: GET_SIGNIN_ERRORS,
        payload: err.response ? err.response.data : err,
      })
      return err
    })
    .finally(() => {
      dispatch({
        type: SIGNIN_LOADING,
        payload: false,
      })
    })
}

export const removeAuthErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_AUTH_ERRORS,
  })
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  }
}

// Log user out
export const logoutUser = (history) => (dispatch) => {
  triggerGoogleAnalyticsEvent('sign_out')
  localStorage.removeItem('tooltip_instruction')
  // Remove token from local storage
  localStorage.removeItem('jwtTokenCTEditor')
  // Remove auth header for future requests
  setAuthToken(false)
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
  dispatch({
    type: SIGNOUT_USER,
  })
}
