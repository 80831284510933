import {
  GET_USER_AGREEMENTS,
  GET_USER_AGREEMENTS_LOADING,
  SAVE_USER_AGREEMENTS,
} from '../../redux/actions/Types'

const initialState = {
  data: [],
  loading: false,
}

export default function UserAgreementReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_AGREEMENTS:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      }
    case GET_USER_AGREEMENTS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_USER_AGREEMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
