import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AWS from 'aws-sdk'
import {
  getAssets,
  resetSigningAssets,
} from '../../../redux/actions/AssetActions'
import { getUserAgreements } from '../../../redux/actions/UserAgreementActions'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import AssetsUploadSection from '../../../components/common/assets-upload-section/AssetsUploadSection'

// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_API_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
})

const HomeContent = ({ counts }) => {
  return (
    <MainPanel>
      <div className="summary">
        <div className="single">
          <p className="status draft">Draft</p>
          <p className="status-value">{counts?.draft}</p>
        </div>
        <div className="single">
          <p className="status signed">Signed</p>
          <p className="status-value">{counts?.signed}</p>
        </div>
        <div className="single">
          <p className="status royalties">Royalties</p>
          <p className="status-value">£0</p>
        </div>
      </div>
      <div className="contract">
        <h3 className="title">Combined Licensing and Provenance Labelling in a Simple Workflow</h3>
        <p>Select the assets to be copyrighted and labelled</p>
        <AssetsUploadSection />
      </div>
    </MainPanel>
  )
}

const Home = () => {
  const [counts, setCounts] = useState({ draft: 0, asserted: 0, signed: 0 })
  const dispatch = useDispatch()
  const { userAgreements, assets } = useSelector((state) => state)

  const handleGetUserAgreements = async () => {
    await dispatch(getUserAgreements())
  }

  const handleGetAssets = async () => {
    await dispatch(getAssets())
  }

  const agreementCounts = () => {
    let draft = 0
    let asserted = 0
    let signed = 0
    assets.data.forEach((asset) => {
      const userAgreement = userAgreements.data.find(
        (x) => x.assetId === asset._id,
      )
      const isAsserted = userAgreement && !userAgreement?.isDraft
      if (isAsserted) {
        asserted++
      } else {
        draft++
      }
    })
    setCounts({ draft, asserted, signed })
  }

  const resetAnySigningAsset = async () => {
    await dispatch(resetSigningAssets())
  }

  useEffect(() => {
    handleGetAssets()
    handleGetUserAgreements()
    resetAnySigningAsset()
  }, [])

  useEffect(() => {
    agreementCounts()
  }, [assets.data, userAgreements.data])

  return (
    <div className="tikbox-home">
      <div className="tikbox-home__content">
        <SidePanel />
        <HomeContent counts={counts} />
      </div>
    </div>
  )
}

export default Home
