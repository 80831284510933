import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

export const ActionBtns = ({ cancelEditAgreement, saveUpdatedAgreement }) => {
  return (
    <div className="agreement-action-btns">
      <CloseCircleOutlined
        className="cancel-icon"
        onClick={cancelEditAgreement}
      />
      <CheckCircleOutlined
        onClick={saveUpdatedAgreement}
        className="update-icon"
      />
    </div>
  )
}
