import { useState } from 'react'
import SignUpModal from '../main/landing-page/SignUp'
import SignInModal from '../main/landing-page/SignIn'
import Header from '../../components/header/Header'
import { Footer } from '../../components/footer/Footer'

const DMCAPage = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  return (
    <>
      <Header
        setIsSignUpOpen={setIsSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
      <div className="dmca">
        <h3> DMCA Policy</h3>
        <h2>TikBox.io Ltd DMCA Policy</h2>

        <p>
          TikBox.io Ltd respects the intellectual property rights of others and
          expects its users to do the same. In accordance with the Digital
          Millennium Copyright Act of 1998, the text of which may be found on
          the US Copyright Office website at
          http://www.copyright.gov/legislation/dmca.pdf, TikBox.io Ltd will
          respond as quickly as possible to claims of copyright infringement
          committed using our Platform if such claims are reported to TikBox.io
          Ltd's Designated Copyright Agent identified in the sample notice
          below.
        </p>
        <p>
          If you are a copyright owner, authorised to act on behalf of one, or
          authorised to act under any exclusive right under copyright, please
          report alleged copyright infringements taking place on or through our
          Platform by completing the following DMCA Notice of Alleged
          Infringement and delivering it to TikBox.io Ltd’s Designated Copyright
          Agent. Upon receipt of Notice as described below, TikBox.io Ltd will
          take whatever action, in its sole discretion, it deems appropriate,
          including removal of the challenged content from our Platform.{' '}
        </p>

        <h4>DMCA Notice of Alleged Infringement (“Notice”)</h4>

        <ul>
          <li>
            Identify the copyrighted work that you claim has been infringed or –
            if multiple copyrighted works are covered by this Notice – you may
            provide a representative list of the copyrighted works that you
            claim have been infringed.
          </li>
          <li>
            Identify the material or link you claim is infringing (or the
            subject of infringing activity) and to which access is to be
            disabled, including at a minimum, if applicable, the URL of the link
            shown on the Site or the exact location where such material may be
            found.
          </li>

          <li>
            Provide your company affiliation (if applicable), postal address,
            telephone number and, if available, email address.
          </li>
          <li>
            Include both of the following statements in the body of the Notice:
          </li>
          <br></br>
          <li>
            “I hereby state that I have a good faith belief that the disputed
            use of the copyrighted material is not authorised by the copyright
            owner, its agent or the law (e.g. as a fair use)”.<br></br>
            <br></br>
            “I hereby state that the information in this Notice is accurate and,
            under penalty of perjury, that I am the owner, or authorised to act
            on behalf of, the owner, of the copyright or of an exclusive right
            under the copyright that is allegedly infringed”.
          </li>
          <br></br>
          <li>
            Provide your full legal name and your electronic or physical
            signature.
          </li>
          <li>
            Deliver this Notice, with all items completed, to TikBox.io Ltd’s
            Designated Copyright Agent: Copyright Agent, TikBox.io Ltd, 20-22
            Wenlock Road, London, N1 7GU, United Kingdom
          </li>
          <li>Or admin [at] tikbox.io</li>
        </ul>
        <h4>Our process</h4>
        <p>
          We will take down any infringing material and inform the alleged
          infringer. TikBox.io Ltd will pass on a copy of your infringement
          notice to the user, who may submit a counter notice.
        </p>
        <h4>Submitting a counter notice</h4>
        <p>
          If we have taken your content down and you believe this is in error,
          you can submit a counter notice which we will pass on to the person
          who submitted the original notice. You may contact the original
          notifier directly if you want to resolve it directly. If you come to
          an agreement, the reporter must email admin [at] tikbox.io and we’ll
          reinstate your content as quickly as we can. TikBox.io Ltd takes no
          legal responsibility for your counter notice other than passing it
          onto the original filer. We encourage you to seek legal council as
          there may be legal consequences to challenging a notice.
        </p>
      </div>

      {isSignUpOpen && (
        <SignUpModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      {isSignInOpen && (
        <SignInModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      <Footer />
    </>
  )
}

export default DMCAPage
