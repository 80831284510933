import MailchimpFormContainer from './MailchimpFormContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import {
  faFacebookF,
  faXTwitter,
  faYoutube,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import './footer.css'
import { DMCA, PRIVACY_POLICY, TERMS_CONDITIONS } from '../../constants/Routes'

export const Footer = () => {
  return (
    <footer
      id="colophon"
      className="site-footer tikbox-footer"
      role="contentinfo"
      itemScope="itemscope"
      itemType="http://schema.org/WPFooter">
      <div className="content">
        <div className="tikbox-footer__top">
          <div className="container-fluid">
            <div className="tikbox-row">
              <div className="tikbox-column">
                <h6>US BIDEN EXECUTIVE ORDER</h6>
                <p>
                  The Executive Order emphasizes the importance of AI safety,
                  security, and transparency
                </p>
                <ul>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Steps to Align Your Digital Content Strategy with Biden’s
                      AI Copyright Executive Order
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      AI Copyright Rules: A Practical Guide for Content Creators
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Implementing AI Watermarking Techniques to Meet Compliance
                      Standards
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Protecting Your Digital Content with US Executive Order
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Navigating AI Risk Assessments for Your Digital Content
                      Projects
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tikbox-column">
                <h6>EU AI ACT - COMES IN TO FORCE 2nd AUG 2024</h6>
                <p>
                  The Act covers documentation, transparency, and copyright
                  compliance for AI-generated content
                </p>
                <ul>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Ensuring Copyright Compliance in AI Development with the
                      EU AI Act
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Steps to Document AI Training Data as Required by the EU
                      AI Act
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      How developers can comply with opt-out reservations of
                      creators
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Use an API to obtain rightsholders' permsisions for AI
                      training
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Using royalty free content for AI training
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tikbox-column">
                <h6>LICENSING TO COMMERCIALIZE ON CREATIVE COPYRIGHTS</h6>
                <p>
                  TikBox guides for creators to navigate the complexities of
                  copyright and licensing for their digital content
                </p>
                <ul>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Step-by-Step Guide to Licensing Your Creative Works for
                      Commercial Use
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Understanding Creative Commons Licenses: Boosting Exposure
                      and Revenu
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      How to license your Creative Assets for AI Training
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Licensing Creative Content: Balancing Control and
                      Commercial Opportunities
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#" rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Licensing Creative Content: Balancing Control and
                      Commercial Opportunities
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tikbox-column">
                <h6>NEWSLETTER SIGN UP</h6>
                <div
                  id="footer-sidebar-first"
                  className="footer-sidebar-first widget-area"
                  role="complementary">
                  <MailchimpFormContainer />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tikbox-footer__middle"></div>

        <div className="tikbox-footer__bottom">
          <div className="container-fluid">
            <div className="tikbox-row">
              <div className="tikbox-column">
                <ul className="tikbox_social_links">
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faXTwitter} />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/tikbox-io/"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tikbox-column">
                <ul>
                  <li>
                    <Link to={TERMS_CONDITIONS}>Ts & Cs</Link>
                  </li>
                  <li>
                    <Link to={PRIVACY_POLICY}>Privacy</Link>
                  </li>
                </ul>
              </div>
              <div className="tikbox-column">
                <ul>
                  <li>
                    <Link to={DMCA}>DMCA</Link>
                  </li>
                </ul>
              </div>
              <div className="tikbox-column">
                <div className="tikbox-copyright">© Copyright Tikbox 2024</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
