import React, { Component } from 'react'
import ReactModal from 'react-modal'
import Axios from '../../../utils/Axios'
import { FacebookIcon } from '../icons/FacebookIcon'
import { TwitterIcon } from '../icons/TwitterIcon'
import { CopyIcon } from '../icons/CopyIcon'

const urlify = (str) =>
  str.split(' ').join('%20').replace(/'/g, '%27').replace(/:/g, '%3A')

const ShareURL = urlify('https://tikbox.com/')

// : => %3A
// ' => %27
// space => %20

const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const isEmail = (email) => {
  return emailRegexp.test(String(email).toLowerCase())
}

class ShareAppModal extends Component {
  state = {
    loading: false,
    email: '',
  }

  SOCIAL_MEDIA_LINKS = {
    fb: `https://www.facebook.com/sharer/sharer.php?u=${ShareURL}&t=${urlify(
      this.props.ShareText,
    )}`,
    tw: `https://twitter.com/intent/tweet?text=${urlify(this.props.ShareText)}`,
  }

  constructor(props) {
    super(props)
    ReactModal.setAppElement('#root')
  }

  emailChangeHandler = (event) => {
    this.setState({ email: event.target.value })
  }

  sendEmailShareHandler = async () => {
    if (isEmail(this.state.email)) {
      this.setState({ loading: true })

      const response = await Axios.post('/users/shareapp', {
        email: this.state.email,
      })

      if (response.status === 200) {
        this.setState({
          email: '',
          loading: false,
        })
        this.props.closeModal()
      } else {
        this.setState({
          loading: false,
        })
      }
    }
  }

  render() {
    const { loading, email } = this.state
    return (
      <>
        <div
          className="share-app-modal"
          style={{ position: 'relative', height: '40px', marginTop: 16 }}>
          <input
            className={'border px-2 py-2 w-full flex-1'}
            style={{
              outlineColor: isEmail(email) ? 'teal' : '#D1D5DB',
              borderColor: isEmail(email) ? 'teal' : '#D1D5DB',
              color: isEmail(email) ? '#000' : 'grey',
              paddingRight: 88,
              borderRadius: 8,
            }}
            type="email"
            placeholder="Type email..."
            value={email}
            onChange={this.emailChangeHandler}
            readOnly={loading}
          />

          <div
            onClick={this.sendEmailShareHandler}
            className="rounded bg-teal-700 hover:bg-teal-500 text-white flex share-btn"
            style={{
              position: 'absolute',
              top: 1,
              right: 0,
              height: 40,
              width: 75,
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}
            disabled={loading || !isEmail(email)}>
            <span>Share</span>
          </div>
        </div>

        <div
          className="flex w-full mt-7 mb-4"
          style={{ justifyContent: 'center' }}>
          <a href={this.SOCIAL_MEDIA_LINKS.fb} target="blank">
            <div
              style={{
                margin: 8,
              }}
              title="Share of facebook">
              <FacebookIcon />
            </div>
          </a>

          <a href={this.SOCIAL_MEDIA_LINKS.tw} target="blank">
            <div
              style={{
                margin: 8,
              }}
              title="Share on twitter">
              <TwitterIcon />
            </div>
          </a>

          <div
            style={{
              margin: 8,
              cursor: 'pointer',
            }}
            onClick={() => {
              this.props.copyRef.current.click()
            }}
            title="Copy to clipboard">
            <CopyIcon />
          </div>
        </div>
      </>
    )
  }
}

export default ShareAppModal
