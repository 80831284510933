// Authentication
export const GET_SIGNIN_ERRORS = 'get-signin-errors'
export const SIGNIN_LOADING = 'signin-loading'
export const GET_SIGNUP_ERRORS = 'get-register-errors'
export const SIGNUP_LOADING = 'signup-loading'
export const CLEAR_AUTH_ERRORS = 'clear-auth-errors'
export const SET_CURRENT_USER = 'set-authenticated-user'
export const UPDATE_REMOTE_USER_LOAD_STATUS = 'update-remote-user-load-status'
export const REMOTE_USER_LOADED = 'remote-user-loaded'
export const PASSWORD_SET_ERROR = 'password-set-error'
export const SIGNOUT_USER = 'signout-user'

export const SAVE_ASSETS = 'save-assets'
export const RESET_SIGNING_ASSETS = 'reset-signing-assets'
export const GET_ASSETS = 'get-assets'
export const REMOVE_SIGNING_ASSETS = 'remove-signing-assets'
export const GET_ASSETS_LOADING = 'get-assets-loading'

export const GET_AGREEMENTS = 'get-agreements'
export const UPDATE_AGREEMENT = 'update-agreement'
export const UPDATE_AGREEMENT_LOADING = 'update-agreement-loading'
export const GET_AGREEMENTS_LOADING = 'get-agreements-loading'

export const SAVE_USER_AGREEMENTS = 'save-user-agreements'
export const UPDATE_USER_AGREEMENT = 'update-user-agreement'
export const GET_USER_AGREEMENTS = 'get-user-agreements'
export const GET_USER_AGREEMENTS_LOADING = 'get-user-agreements-loading'

export const SAVE_AGREEMENT_TEMPLATE = 'save-agreement-templates'
export const GET_AGREEMENT_TEMPLATES = 'get-agreement-templates'
export const GET_AGREEMENT_TEMPLATES_LOADING = 'get-agreement-templates-loading'

export const GET_TASKS = 'get-tasks'
export const GET_TASKS_LOADING = 'get-tasks-loading'

export const TOGGLE_SIDEBAR = 'toggle-sidebar'
