export const EmptyDocumentIcon = () => {
  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M78 44.1816C78 45.0395 77.5711 45.9527 76.7131 46.9213L62.7653 63.3599C61.5753 64.7712 59.9079 65.9681 57.7632 66.9506C55.6184 67.933 53.6328 68.4242 51.8063 68.4242H6.64184C5.70091 68.4242 4.86376 68.2444 4.13039 67.8846C3.39702 67.5248 3.03033 66.9298 3.03033 66.0996C3.03033 65.2417 3.45929 64.3284 4.3172 63.3599L18.265 46.9213C19.455 45.5099 21.1224 44.313 23.2672 43.3306C25.4119 42.3481 27.3976 41.8569 29.2241 41.8569H74.3885C75.3294 41.8569 76.1666 42.0368 76.9 42.3966C77.6333 42.7563 78 43.3513 78 44.1816ZM63.7616 29.9016V36.5434H29.2241C26.6227 36.5434 23.8967 37.2007 21.0463 38.5152C18.1959 39.8297 15.9266 41.4833 14.2384 43.4759L0.24906 59.9144L0.0415246 60.1635C0.0415246 60.0528 0.0346038 59.8798 0.0207623 59.6446C0.00692076 59.4093 0 59.2364 0 59.1257V19.2747C0 16.7286 0.91325 14.5424 2.73975 12.7158C4.56625 10.8893 6.75253 9.97607 9.29858 9.97607H22.5822C25.1282 9.97607 27.3145 10.8893 29.1411 12.7158C30.9676 14.5423 31.8808 16.7286 31.8808 19.2747V20.603H54.463C57.0091 20.603 59.1953 21.5163 61.0218 23.3428C62.8483 25.1693 63.7616 27.3555 63.7616 29.9016V29.9016Z"
        fill="#DDDDDD"
      />
    </svg>
  )
}
