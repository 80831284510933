import { TOGGLE_SIDEBAR } from '../../redux/actions/Types'

const initialState = {
  data: { sidebarOpen: window.innerWidth > 768 ? true : false },
  loading: false,
}

export default function NavigationReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      }
    default:
      return state
  }
}
