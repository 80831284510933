import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Modal,
} from '@chakra-ui/react'

const TikBoxModal = ({ maxW = '490px', title, open, toggle, ...props }) => {
  return (
    <Modal size="lg" onClose={() => toggle(false)} isOpen={open} isCentered>
      <ModalOverlay />
      <ModalContent
        position={'fixed'}
        bottom={window.innerWidth > 768 ? 'inherit' : 0}
        borderRadius="2xl"
        borderBottomRadius={window.innerWidth > 768 ? '2xl' : 0}
        paddingBottom={5}
        paddingTop={3}
        maxW={maxW}>
        <ModalHeader style={{ fontWeight: 700 }}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{props.children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TikBoxModal
