export const getRgbColor = (color) => {
  if (typeof color === 'string' && color.indexOf('#') > -1) {
    return color
  }

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

export const triggerGoogleAnalyticsEvent = (eventName, metaData) => {
  window.gtag('event', eventName, metaData)
}

export const getNameIntials = (name) => {
  let initials = ''
  const [firstName, lastName] = name.split(' ')
  if (firstName) {
    initials += firstName[0].toUpperCase()
  }
  if (lastName) {
    initials += lastName[0].toUpperCase()
  }
  return initials
}

export const roundDown = (value) => {
  return Math.floor(value * 10) / 10
}

export const getAssetInMB = (sizeByte) => {
  return roundDown(sizeByte / 1000000)
}
