import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// import 'materialize-css/dist/css/materialize.css'
import 'tailwindcss/dist/tailwind.css'
import './assets//tailwind.css'
import './assets/main.css'
import './assets/index.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-aspect-ratio/aspect-ratio.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'react-tippy/dist/tippy.css'

// import 'materialize-css'
import { ToastContainer } from 'react-toastify'
import store from './redux'
import App from './App'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  components: {},
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root'),
)
