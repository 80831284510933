import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  getNameIntials,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../redux/actions/AuthActions'
import './main-panel.scss'
import { SidebarToggleIcon } from '../icons/SidebarToggleIcon'
import { toggleSidebar } from '../../../redux/actions/NavigationActions'
import {
  HOME,
  TERMS_CONDITIONS,
  PRIVACY_POLICY,
} from '../../../constants/Routes'
import Logo from '../../../assets/images/TIKBOX_logo_Blue.jpg'
import { DropdownIcon } from '../icons/DropdownIcon'
import { AccountModal } from '../modal/AccountModal'
import { FollowModal } from '../modal/FollowModal'
import ShareModal from '../modal/ShareModal'

const MainPanel = (props) => {
  const dispatch = useDispatch()
  const {
    auth: { user },
    navigations: {
      data: { sidebarOpen },
    },
  } = useSelector((state) => state)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [toggleAccountModal, setToggleAccountModal] = useState(false)
  const [toggleFollowModal, setToggleFollowModal] = useState(false)
  const [toggleShareModal, setToggleShareModal] = useState(false)

  const updateToggleAccountModal = (value) => {
    triggerGoogleAnalyticsEvent('toggle_profile_update_modal', {
      userId: user?.id,
    })
    setToggleAccountModal(value)
  }
  const updateToggleFollowModal = (value) => {
    triggerGoogleAnalyticsEvent('toggle_follow_modal', {
      userId: user?.id,
    })
    setToggleFollowModal(value)
  }
  const updateToggleShareModal = (value) => {
    triggerGoogleAnalyticsEvent('toggle_share_modal', {
      userId: user?.id,
    })
    setToggleShareModal(value)
  }

  const toggleDropdown = (value) => {
    setOpenDropdown(value)
  }

  const toggleSidebarFunc = () => {
    dispatch(toggleSidebar({ sidebarOpen: !sidebarOpen }))
  }

  const Signout = () => {
    triggerGoogleAnalyticsEvent('user_signout', {
      userId: user?.id,
    })
    dispatch(logoutUser())
  }

  useEffect(() => {
    if (openDropdown) {
      window.addEventListener('click', (e) => {
        let hasDropdownClass = false
        e.target.classList.forEach((className) => {
          if (className === 'dropdown-toggler') {
            hasDropdownClass = true
          }
        })
        if (!hasDropdownClass) {
          toggleDropdown(false)
        }
      })
    } else {
      window.removeEventListener('click', () => {
        toggleDropdown(false)
      })
    }
  }, [openDropdown])

  return (
    <div className={`tikbox-main-panel${!sidebarOpen ? ' full' : ''}`}>
      <div className="overlay" onClick={toggleSidebarFunc}></div>
      <div className="tikbox-main-panel-content">
        <div className="tikbox-main-panel__header">
          <div className="tikbox-main-panel__header-logo">
            {!sidebarOpen && (
              <SidebarToggleIcon
                style={{
                  transform: 'rotate(180deg)',
                }}
                onClick={toggleSidebarFunc}
              />
            )}
            {!sidebarOpen && (
              <Link to={HOME}>
                <img className="logo" src={Logo} alt="logo" />
              </Link>
            )}
            <div className="salutation">
              <span style={{ fontSize: 14 }}>Hello,</span>{' '}
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                {user.name.split(' ')[0]}
              </span>
            </div>
          </div>
          <div className="tikbox-main-panel__header-profile">
            <div className="question tikbox-center">?</div>

            <div
              className="profile tikbox-center"
              onClick={() => toggleDropdown(true)}>
              <span className="initials dropdown-toggler">
                {getNameIntials(user.name)}
              </span>
              <DropdownIcon
                onClick={() => toggleDropdown(true)}
                className="dropdown-toggler"
                style={{ position: 'absolute', right: -19, top: 10 }}
              />
              <div className={`profile-dropdown${openDropdown ? ' open' : ''}`}>
                <ul>
                  <li onClick={() => updateToggleAccountModal(true)}>
                    My profile
                  </li>
                  <li onClick={() => updateToggleShareModal(true)}>
                    Share app
                  </li>
                  <li onClick={() => updateToggleFollowModal(true)}>
                    Follow us
                  </li>
                  <li>
                    <Link target="_blank" to={PRIVACY_POLICY}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={TERMS_CONDITIONS}>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li onClick={Signout}>Logout</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tikbox-main-panel__children">
          <div className="tikbox-main-panel__children-content">
            {props.children}
          </div>
        </div>
      </div>
      <AccountModal
        open={toggleAccountModal}
        onClose={() => updateToggleAccountModal(false)}
        title="Account Information"
      />
      <FollowModal
        open={toggleFollowModal}
        onClose={() => updateToggleFollowModal(false)}
        title="Follow us"
      />

      <ShareModal
        open={toggleShareModal}
        onClose={() => updateToggleShareModal(false)}
        title="Share Tikbox"
      />
    </div>
  )
}

export default MainPanel
