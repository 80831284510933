import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { SoundUploadIcon } from '../common/icons/SoundUploadIcon'
import { ImageUploadIcon } from '../common/icons/ImageUploadIcon'
import { VideoUploadIcon } from '../common/icons/VideoUploadIcon'
import { PDFUploadIcon } from '../common/icons/PDFUploadIcon'
import { useState } from 'react'
import { getAssetInMB } from '../../utils/Helper'
import { ImageMultipleUploadIcon } from '../common/icons/ImageMultipleUploadIcon'
import { VideoMultipleUploadIcon } from '../common/icons/VideoMultipleUploadIcon'
import { PDFMultipleUploadIcon } from '../common/icons/PDFMultipleUploadIcon'
import { SoundMultipleUploadIcon } from '../common/icons/SoundMultipleUploadIcon'
import UploadBoxStatus from '../common/upload-box-status/UploadBoxStatus'
import { ASSET_UPLOAD_TYPE } from '../../constants'

const UploadBox = ({
  type,
  uploadProgress,
  assetType,
  setAssetType,
  onDrop,
  uploading,
  resetAssetUplaoded,
  disabled,
  toggleUploadsModal,
  uploadAssetType,
  assetsUploadProgresses,
}) => {
  const [sizeTotal, setSizeTotal] = useState(0)
  const uploadAsset = (...args) => {
    if (
      uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE &&
      !args[0][0]?.type.includes(type)
    ) {
      return toast.error(`Only ${type} file  is allowed!`)
    }
    if (uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE) {
      let valid = true
      args[0].forEach((file) => {
        if (!file?.type.includes(type)) {
          valid = false
        }
      })
      if (!valid) {
        return toast.error(`Only ${type} file  is allowed!`)
      }
    }
    if (uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE && args[0].length > 1) {
      return toast.error(
        'You can only upload one asset at a time. Select multiple asset(s) option.',
      )
    }
    setAssetType(type)
    const size = getAssetInMB(args[0][0]?.size)
    setSizeTotal(size)
    onDrop(...args)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadAsset,
  })
  return (
    <div
      className={`upload-box${assetType === type ? ' top' : ''}${
        disabled ? ' disabled' : ''
      }${uploadProgress === 100 && assetType !== type ? ' disabled' : ''}${
        uploadProgress === 100 && assetType === type ? ' complete-upload' : ''
      }`}>
      <div className="upload-box-area" {...getRootProps()}>
        <input
          {...getInputProps()}
          accept={
            type === 'image'
              ? 'image/*'
              : type === 'video'
              ? 'video/*'
              : type === 'pdf'
              ? 'application/pdf'
              : 'audio/*'
          }
        />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <div className="upload-box-left">
              {type === 'image' &&
              uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE ? (
                <ImageUploadIcon />
              ) : type === 'image' &&
                uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE ? (
                <ImageMultipleUploadIcon />
              ) : type === 'video' &&
                uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE ? (
                <VideoUploadIcon />
              ) : type === 'video' &&
                uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE ? (
                <VideoMultipleUploadIcon />
              ) : type === 'pdf' &&
                uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE ? (
                <PDFUploadIcon />
              ) : type === 'pdf' &&
                uploadAssetType === ASSET_UPLOAD_TYPE.MULTIPLE ? (
                <PDFMultipleUploadIcon />
              ) : uploadAssetType === ASSET_UPLOAD_TYPE.SINGLE ? (
                <SoundUploadIcon />
              ) : (
                <SoundMultipleUploadIcon />
              )}
              <div>
                <p className="upload-box-left__title">
                  Select{' '}
                  {type === 'image'
                    ? 'image(s)'
                    : type === 'video'
                    ? 'video(s)'
                    : type === 'pdf'
                    ? 'PDF(s)'
                    : 'Music/Sound(s)'}
                </p>
                {/* <p className="upload-box-left__size">Minimum file 3GB</p> */}
              </div>
            </div>
            <button className="upload-box__btn">
              {window.innerWidth > 768 ? 'Click to upload' : 'Upload'}
            </button>
            {/* <p className="upload-box__size">Minimum file 3GB</p> */}
          </>
        )}
      </div>
      {uploading && (
        <UploadBoxStatus
          uploadProgress={uploadProgress}
          sizeTotal={sizeTotal}
          resetAssetUplaoded={resetAssetUplaoded}
          toggleUploadsModal={toggleUploadsModal}
          uploadAssetType={uploadAssetType}
          assetsUploadProgresses={assetsUploadProgresses}
          showMultipleSummary={true}
        />
      )}
    </div>
  )
}

export default UploadBox
