import { Link } from 'react-router-dom'
import { faFileImage, faListAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AgreementHeaderCheckedIcon } from '../../../components/common/icons/AgreementHeaderCheckedIcon'
import { AgreementHeaderReviewIcon } from '../../../components/common/icons/AgreementHeaderReviewIcon'
// import { AgreementHeaderBoilerPlateIcon } from '../../../components/common/icons/AgreementHeaderBoilerPlateIcon'
import Logo from '../../../assets/images/TIKBOX_logo_Blue.jpg'
import { HOME } from '../../../constants/Routes'
import { AgreementHeaderInfoIcon } from '../../../components/common/icons/AgreementHeaderInfoIcon'
import { AgreementHeaderCloseIcon } from '../../../components/common/icons/AgreementHeaderCloseIcon'

const AgreementHeader = ({ step, closeAgreement }) => {
  return (
    <div className="agreement-header">
      <div className="agreement-header__logo">
        <Link to={HOME}>
          <img className="logo" src={Logo} alt="logo" />
        </Link>
      </div>
      <div className="agreement-header__sections">
        <div className="title">
          <AgreementHeaderCheckedIcon />
          <p>Select Assets</p>
        </div>
        <div className="title">
          <AgreementHeaderCheckedIcon />
          <p>Assignment</p>
        </div>
        <div className="title">
          {step > 1 ? (
            <AgreementHeaderCheckedIcon />
          ) : (
            <span className={`tikbox-center${step > 1 ? ' selected' : ''}`}>
              <FontAwesomeIcon icon={faListAlt} />
            </span>
          )}
          <p>License</p>
        </div>
        <div className="title">
          {step > 2 ? (
            <AgreementHeaderCheckedIcon />
          ) : (
            <span className={`tikbox-center${step > 2 ? ' selected' : ''}`}>
              £
            </span>
          )}
          <p>Royalty</p>
        </div>
        <div className="title">
          {step > 3 ? (
            <AgreementHeaderCheckedIcon />
          ) : (
            // <AgreementHeaderBoilerPlateIcon />
            <span className={`tikbox-center${step > 3 ? ' selected' : ''}`}>
              Aa
            </span>
          )}
          <p>Boilerplate</p>
        </div>
        <div className="title">
          {step > 4 ? (
            <AgreementHeaderCheckedIcon />
          ) : (
            <AgreementHeaderReviewIcon />
          )}
          <p>Review & Finish</p>
        </div>
      </div>

      <div className="agreement-header__action-btns">
        <AgreementHeaderInfoIcon />
        <AgreementHeaderCloseIcon onClick={closeAgreement} />
      </div>
    </div>
  )
}

export default AgreementHeader
