const Features = () => {
  return (
    <section className="tikbox-features">
      <div className="tikbox-container">
        <h3>Provenance & Copyright Solutions for the Age of AI </h3>
      </div>
    </section>
  )
}

export default Features
