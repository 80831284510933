import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Logo from '../../assets/images/TIKBOX_logo_Blue.jpg'

import { LANDING, SIGN_IN, SIGN_UP } from '../../constants/Routes'

class NavigationHeader extends Component {
  render() {
    return (
      <div className="masthead py-12 navHeader">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
          <div className="site-logo flex justify-center sm:flex justify-center md:flex justify-center lg:flex justify-end">
            <Link
              to={LANDING}
              className="site-logo-link"
              rel="home"
              itemProp="url">
              <img
                src={Logo}
                className="site-logo attachment-dicot-site-logo"
                alt=""
                data-size="dicot-site-logo"
                itemProp="logo"
              />
            </Link>
          </div>
          <div className="flex justify-center uppercase text-xl font-normal items-center "></div>
          {/* <div
            className="flex justify-center uppercase text-xl font-normal items-center "
            style={{ fontFamily: 'Oswald,sans-serif' }}>
            <span className="px-4 hover:text-green-700 cursor-pointer">
              <a href="https://web.tikbox.com/blog/">BLOG</a>
            </span>
            <span className="px-4 hover:text-green-700 cursor-pointer">
              <a href="https://web.tikbox.com/pro-social-media-video-content-creation/">
                PRO
              </a>
            </span>
            <span className="px-4 hover:text-green-700 cursor-pointer">
              <a href="https://web.tikbox.com/pricing/">PRICING</a>
            </span>
          </div> */}
          <div className="flex justify-center items-center sm:justify-center mt-4 md:justify-center mt-4 lg:justify-end xl:justify-end">
            <Link to={SIGN_IN}>
              <button
                className="border-gray-400 border-solid border-2 bg-white hover:bg-gray-100 text-gray-600 font-bold py-2 px-4 rounded shadow mr-4  h-16"
                style={{ width: '7rem' }}>
                <span className="text-xl hover:text-gray-800 ">Log in</span>
              </button>
            </Link>
            <Link to={SIGN_UP}>
              <button
                className="text-xl bg-teal-700 hover:bg-teal-400 text-white font-bold py-2 px-4 rounded h-16 menu-signup-btn"
                style={{ width: '7rem' }}>
                Sign up
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(NavigationHeader)
