import { toast } from 'react-toastify'
import instance from '../../utils/Axios'

import {
  GET_AGREEMENT_TEMPLATES,
  GET_AGREEMENT_TEMPLATES_LOADING,
  SAVE_AGREEMENT_TEMPLATE,
} from './Types'

// Save Agreement Template
export const saveAgreementTemplate = (data, callback) => (dispatch) => {
  dispatch({
    type: GET_AGREEMENT_TEMPLATES_LOADING,
    payload: true,
  })
  instance
    .post('/agreement-templates', data)
    .then((res) => {
      toast.success('Agreement template saved successfully')
      dispatch({
        type: SAVE_AGREEMENT_TEMPLATE,
        payload: res.data.message,
      })
      callback && callback(res.data.message._id)
    })
    .catch((err) => {
      toast.error('Error saving agreement template')
    })
    .finally(() => {
      dispatch({
        type: GET_AGREEMENT_TEMPLATES_LOADING,
        payload: false,
      })
    })
}

// Get Agreement Templates
export const getAgreementTemplates = () => (dispatch) => {
  dispatch({
    type: GET_AGREEMENT_TEMPLATES_LOADING,
    payload: true,
  })
  instance
    .get('/agreement-templates')
    .then((res) => {
      dispatch({
        type: GET_AGREEMENT_TEMPLATES,
        payload: res.data.message,
      })
    })
    .catch(() => {
      toast.error('Error fetching agreement templates')
    })
    .finally(() => {
      dispatch({
        type: GET_AGREEMENT_TEMPLATES_LOADING,
        payload: false,
      })
    })
}
