import { toast } from 'react-toastify'
import instance from '../../utils/Axios'

import {
  GET_USER_AGREEMENTS,
  GET_USER_AGREEMENTS_LOADING,
  SAVE_USER_AGREEMENTS,
  UPDATE_USER_AGREEMENT,
} from './Types'

// Save user agreement
export const saveUserAgreement = (agreementData) => (dispatch) => {
  instance
    .post('/user-agreements', agreementData)
    .then((res) => {
      dispatch({
        type: SAVE_USER_AGREEMENTS,
        payload: res.data.message.assets,
      })
      if (res.data.message.PDFUrl) {
        window.open(res.data.message.PDFUrl, '_blank')
      }
    })
    .catch((err) => {
      toast.error('Error saving user agreement')
    })
}

// Get user agreements
export const getUserAgreements = () => (dispatch) => {
  dispatch({
    type: GET_USER_AGREEMENTS_LOADING,
    payload: true,
  })
  instance
    .get('/user-agreements')
    .then((res) => {
      dispatch({
        type: GET_USER_AGREEMENTS,
        payload: res.data.message,
      })
    })
    .catch((err) => {
      if (err?.response?.data?.authentication) {
        toast.error(err?.response?.data?.authentication)
      }
    })
    .finally(() => {
      dispatch({
        type: GET_USER_AGREEMENTS_LOADING,
        payload: false,
      })
    })
}

// Update user agreement
export const updateUserAgreement = (assetId, agreementData) => (dispatch) => {
  instance
    .put(`/user-agreements/${assetId}/update`, agreementData)
    .then((res) => {
      dispatch({
        type: UPDATE_USER_AGREEMENT,
        payload: res.data.message,
      })
    })
    .catch((err) => {
      toast.error('Error updating user agreement')
    })
}
