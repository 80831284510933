import AppModal from './AppModal'
import { FacebookIcon } from '../icons/FacebookIcon'
import { InstagramIcon } from '../icons/InstagramIcon'
import { LinkedinIcon } from '../icons/LinkedinIcon'
import { TiktokIcon } from '../icons/TikTokIcon'
import { TwitterIcon } from '../icons/TwitterIcon'
import { YoutubeIcon } from '../icons/YoutubeIcon'

export const FollowModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title} hideHeader>
      <>
        <div className="shareIcons">
          <a href="https://www.facebook.com/tikbox" target="blank">
            <FacebookIcon />
          </a>
          <a href="https://www.youtube.com/user/tikbox" target="blank">
            <YoutubeIcon />
          </a>
          <a href="https://twitter.com/tikbox" target="blank">
            <TwitterIcon />
          </a>
          <a href="https://www.tiktok.com/tikbox" target="blank">
            <TiktokIcon />
          </a>
          <a href="https://www.instagram.com/tikbox" target="blank">
            <InstagramIcon />
          </a>
          <a href="https://www.linkedin.com/company/tikbox" target="blank">
            <LinkedinIcon />
          </a>
        </div>
      </>
    </AppModal>
  )
}
