import React, { useState } from 'react'
import './sections.scss'

import Header from '../../../components/header/Header'
import Banner from './Banner'
import Features from './Feature'
import Services from './Services'
import SignUpModal from './SignUp'
import SignInModal from './SignIn'

const LandingPageSections = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  return (
    <>
      <Header
        setIsSignUpOpen={setIsSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
      <Banner setIsSignUpOpen={setIsSignUpOpen} />
      <Features />
      <Services setIsSignUpOpen={setIsSignUpOpen} />
      {isSignUpOpen && (
        <SignUpModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      {isSignInOpen && (
        <SignInModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
    </>
  )
}

export default LandingPageSections
