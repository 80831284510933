import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthFooter from '../../components/auth/AuthFooter'
import { SignInForm } from '../../components/auth/SignIn'
import { SIGN_UP } from '../../constants/Routes'
import './landing-page.scss'
import NavigationHeader from '../../components/navigation/NavigationHeader'

class LandingPage extends Component {
  render() {
    return (
      <>
        <div className="home landing container mx-auto landing-page">
          <NavigationHeader />

          <div className="home-content">
            <div className="flex flex-col md:flex-col lg:flex-row xl:flex-row">
              <div className="w-full relative sm:w-full md:w-full lg:w-2/3 xl:w-2/3 pl-4">
                <p className="bg-img-text">
                  Assert and monitise on your copyrighted creatives with simple
                  digital tools.
                </p>
              </div>

              <div className="w-full md:w-full lg:w-1/3 xl:w-1/3">
                <div className="w-full float-right">
                  <SignInForm />
                  <div className="flex justify-center items-center">
                    <span className="font-medium text-gray-600">
                      Don't have an account?{' '}
                    </span>
                    <Link
                      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 cursor-pointer"
                      style={{ marginLeft: 5 }}
                      to={SIGN_UP}>
                      {' '}
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AuthFooter />
      </>
    )
  }
}

export default LandingPage
