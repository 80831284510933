import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AgreementContentType } from '../../../constants'
import { AgreementItem } from './AgreementItem'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const BoilerplateContent = ({
  data,
  editingAgreement,
  setEditAgreement,
  agreementContent,
  setAgreementContent,
  cancelEditAgreement,
  saveUpdatedAgreement,
}) => {
  const {
    auth: { user },
  } = useSelector((state) => state)
  const titles = data.filter(
    (x) => x.contentType === AgreementContentType.TITLE,
  )
  const subtitles = data.filter(
    (x) => x.contentType === AgreementContentType.SUBTITLE,
  )
  const paragraphs = data.filter(
    (x) => x.contentType === AgreementContentType.PARAGRAPH,
  )
  const ols = data.filter((x) => x.contentType === AgreementContentType.OL)

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'boilerplate',
      userId: user?.id,
    })
  }, [])

  return (
    <>
      {titles.map((title) => (
        <AgreementItem
          editingAgreement={editingAgreement}
          setEditAgreement={setEditAgreement}
          cancelEditAgreement={cancelEditAgreement}
          saveUpdatedAgreement={saveUpdatedAgreement}
          agreementContent={agreementContent}
          setAgreementContent={setAgreementContent}
          agreement={title}
        />
      ))}
      <div className="mb-4">
        {subtitles.length && (
          <AgreementItem
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            agreement={subtitles[0]}
          />
        )}
        {paragraphs.length && (
          <AgreementItem
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            agreement={paragraphs[0]}
          />
        )}
        {ols.length && (
          <ol>
            {ols
              .sort((a, b) => (a.code > b.code ? 1 : -1))
              .map((ol) => (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={ol}
                />
              ))}
          </ol>
        )}
      </div>
      <div>
        {subtitles.length > 1 && (
          <AgreementItem
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            agreement={subtitles[1]}
          />
        )}
        {paragraphs.length > 1 && (
          <AgreementItem
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            agreement={paragraphs[1]}
          />
        )}
      </div>
    </>
  )
}

export default BoilerplateContent
