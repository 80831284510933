export const SIGN_UP = '/signup'
export const CONFIRM_USER = '/confirm-user/:token'
export const WOOCOMMERCE_SIGN_UP = '/wc/:orderKey'
export const SIGN_IN = '/signin'
export const PASSWORD_FORGET = '/pw-forget'
export const PASSWORD_RESET = '/pw-reset/:token'
export const PRICING = '/pricing'
export const LANDING = '/'
export const HOME = '/user/home'
export const AGREEMENT = '/user/agreement'
export const ASSETS = '/user/assets'
export const USER_VERIFY = '/user/verify'
export const USER_TEMPLATE_AGREEMENT = '/user/template-agreement'
export const VERIFY = '/verify'
export const PRIVACY_POLICY = '/privacy-policy'
export const TERMS_CONDITIONS = '/terms-conditions'
export const DMCA = '/dmca'

// admin routes
export const ADMIN = '/admin'
export const ADMIN_LIST_USERS = '/admin/users'
export const ADMIN_SHOW_USER = '/admin/users/:id'
export const ADMIN_INVITE_MEMBERS = '/admin/invite-members'
