import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { loginUser, removeAuthErrors } from '../../redux/actions/AuthActions'
import { HOME, PASSWORD_FORGET, SIGN_IN } from '../../constants/Routes'
import { SignUpLink } from './SignUp'
import AuthFooter from './AuthFooter'
import NavigationHeader from '../navigation/NavigationHeader'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

const SignInPage = () => (
  <>
    <div className={'container mx-auto signInWraper'}>
      <NavigationHeader />
      <div className="w-full max-w-xs signInContent">
        <SignInForm />

        <SignUpLink />
      </div>
    </div>
    <AuthFooter />
  </>
)

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: {},
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    let redirect = params.get('redirect')
    let ref = params.get('verify_ref')
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(
        `${redirect ? `${redirect}?verify_ref=${ref}` : HOME}`,
      )
    }
  }

  componentDidUpdate() {
    const loginError = this.props.auth.loginError
    if (loginError) {
      toast.dismiss()
      toast.error(loginError)

      this.props.removeAuthErrors()
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.auth.isAuthenticated) {
      const params = new URLSearchParams(nextProps.location.search)
      let redirect = params.get('redirect')
      let ref = params.get('verify_ref')
      nextProps.history.push(
        `${redirect ? `${redirect}?verify_ref=${ref}` : HOME}`,
      )
    }

    return null
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('login', { method: 'Form' })

    const userData = {
      email: this.state.email,
      password: this.state.password,
    }

    this.props.loginUser(userData)
  }

  render() {
    const { email, password, errors } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form
        noValidate
        onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 auth-form">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="email"
            value={email}
            error={errors.email}
            name="email"
            onChange={this.onChange}
            placeholder="Email Address"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            name="password"
            placeholder="password"
            value={password}
            error={errors.password}
            onChange={this.onChange}
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid || this.props.auth.signInLoading
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid || this.props.auth.signInLoading}
            type="submit">
            {this.props.auth.signInLoading ? 'Signing in...' : 'Sign In'}
          </button>
          <Link
            to={PASSWORD_FORGET}
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
            Forgot Password?
          </Link>
        </div>
      </form>
    )
  }
}

// eslint-disable-next-line no-lone-blocks
{
  /* <PasswordForgetLink /> */
}
SignInFormBase.propTypes = {
  loginUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const SignInForm = connect(mapStateToProps, { loginUser, removeAuthErrors })(
  withRouter(SignInFormBase),
)

const SignInLink = () => (
  <p className="text-center w-full">
    Do have an account?{' '}
    <Link
      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
      to={SIGN_IN}>
      Sign In
    </Link>
  </p>
)

export default SignInPage

export { SignInForm, SignInLink }
