import moment from 'moment'
import {
  AgreementCategory,
  AgreementContentType,
  AgreementKeys,
} from '../../../constants'
import { AgreementItem } from './AgreementItem'
import { useEffect } from 'react'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const transformRoyaltyValue = (str, royaltyVal, duration) => {
  str = str.replace(/{{royalty_value}}/g, royaltyVal)
  str = str.replace(/{{duration}}/g, duration)

  return str
}

const ReviewContent = ({
  data,
  editingAgreement,
  setEditAgreement,
  cancelEditAgreement,
  saveUpdatedAgreement,
  setAgreementContent,
  agreementContent,
  agreementData,
  selectedAgreements,
  user,
  signingAssets,
  acceptedTerms,
  acceptAgreementTerms,
  reviewRef,
}) => {
  const boilerplateParagraphs = agreementData.filter(
    (x) =>
      x.contentType === AgreementContentType.PARAGRAPH &&
      x.category === AgreementCategory.BOILERPLATE,
  )
  const boilerplateOls = agreementData.filter(
    (x) =>
      x.contentType === AgreementContentType.OL &&
      x.category === AgreementCategory.BOILERPLATE,
  )
  const boilerplateSubtitles = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.BOILERPLATE,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const royaltySubtitles = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.ROYALTY,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const royaltyParagraphs = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.ROYALTY,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const licenseSubtitles = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.LICENSE,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const licenseParagraphs = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.LICENSE,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const assignmentSubtitles = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.ASSIGNMENT,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const assignmentParagraphs = agreementData
    .filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.ASSIGNMENT,
    )
    .sort((a, b) => (a.code > b.code ? 1 : -1))

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'review',
      userId: user?.id,
    })
  }, [])

  return (
    <div className="review-content">
      <h2>Review & Finish</h2>
      <div ref={reviewRef} style={{ padding: 15 }}>
        <div className="mb-4">
          <h3 className="mb-3">Copyright Agreement</h3>
          <p style={{ fontSize: 16 }}>
            This agreement is date {moment().format('YYYY-MM-DD')} and made
            between:
          </p>
          <ol>
            <li style={{ fontSize: 15 }}>
              1. {user.name} (hereafter "the license")
            </li>
            <li style={{ fontSize: 15 }}>
              2. [Name of licensee] of [address of licensee]{' '}
            </li>
          </ol>
          <p>
            The licensor wishes to license the Asset{' '}
            {signingAssets
              .map(({ originalFileName }) => originalFileName)
              .toString()}
          </p>
        </div>
        {selectedAgreements[AgreementKeys.Assignment].length > 0 && (
          <div className="mb-4">
            {assignmentSubtitles[0] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={assignmentSubtitles[0]}
              />
            )}
            {assignmentParagraphs[0] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={assignmentParagraphs[0]}
              />
            )}

            <div className="questionaire" style={{ marginTop: 10 }}>
              <form>
                {agreementData
                  .filter(
                    (x) =>
                      x.category === AgreementCategory.ASSIGNMENT &&
                      x.contentType === AgreementContentType.CHECKBOX,
                  )
                  .map((checkbox, index) => {
                    const isChecked = !!selectedAgreements[
                      AgreementKeys.Assignment
                    ].find((x) => x.index === checkbox._id)
                    return (
                      isChecked && (
                        <div
                          className="tikbox-form-element"
                          key={index}
                          style={{ marginBottom: 0 }}>
                          <input
                            type="checkbox"
                            id={index}
                            checked={isChecked}
                            name={index}
                            disabled
                            style={{ width: 20 }}
                          />
                          <label htmlFor={index}>{checkbox.content}</label>
                        </div>
                      )
                    )
                  })}
              </form>
            </div>
          </div>
        )}

        {selectedAgreements[AgreementKeys.License].length > 0 && (
          <div className="mb-4">
            {licenseSubtitles[0] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={licenseSubtitles[0]}
              />
            )}
            {licenseParagraphs[0] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={licenseParagraphs[0]}
              />
            )}

            <div className="questionaire" style={{ marginTop: 10 }}>
              <form>
                {agreementData
                  .filter(
                    (x) =>
                      x.category === AgreementCategory.LICENSE &&
                      x.contentType === AgreementContentType.CHECKBOX,
                  )
                  .map((checkbox, index) => {
                    const isChecked = !!selectedAgreements[
                      AgreementKeys.License
                    ].find((x) => x.index === checkbox._id)
                    return (
                      isChecked && (
                        <div
                          className="tikbox-form-element"
                          key={index}
                          style={{ marginBottom: 0 }}>
                          <input
                            type="checkbox"
                            id={index}
                            checked={isChecked}
                            name={index}
                            disabled
                            style={{ width: 20 }}
                          />
                          <label htmlFor={index}>{checkbox.content}</label>
                        </div>
                      )
                    )
                  })}
              </form>
            </div>
          </div>
        )}

        <div className="mb-4">
          {royaltySubtitles[0] && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={royaltySubtitles[0]}
            />
          )}
          {royaltyParagraphs[0] && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={royaltyParagraphs[0]}
              displayText={transformRoyaltyValue(
                royaltyParagraphs[0].content,
                selectedAgreements[AgreementKeys.RoyaltyValue],
                agreementData.find(
                  (x) =>
                    x._id === selectedAgreements[AgreementKeys.RoyaltyLength],
                )?.content,
              )}
            />
          )}
          {selectedAgreements[AgreementKeys.RoyaltyAcknowledgement] &&
            royaltyParagraphs[1] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={royaltyParagraphs[1]}
                displayText={`${royaltyParagraphs[1].content} ${
                  selectedAgreements[AgreementKeys.RoyaltyAcknowledgement]
                }`}
              />
            )}
          {/* {selectedAgreements[AgreementKeys.RoyaltyAcknowledgement] && (
            <p style={{ fontSize: 16 }}>
              The following acknowledgement of the original creator of the Asset
              must be made: "
              {selectedAgreements[AgreementKeys.RoyaltyAcknowledgement]}"
            </p>
          )} */}
        </div>

        <div className="mb-4">
          {boilerplateSubtitles[0] && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={boilerplateSubtitles[0]}
            />
          )}
          {boilerplateParagraphs.length && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={boilerplateParagraphs[0]}
            />
          )}
          {boilerplateOls.length && (
            <ol>
              {boilerplateOls
                .sort((a, b) => (a.code > b.code ? 1 : -1))
                .map((ol) => (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={ol}
                  />
                ))}
            </ol>
          )}
          {/* {boilerplateOls.length && (
            <ol>
              {boilerplateOls.map((ol, index) => (
                <li key={index}>{ol.content}</li>
              ))}
            </ol>
          )} */}
        </div>

        <div className="mb-4">
          {boilerplateSubtitles[1] && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={boilerplateSubtitles[1]}
            />
          )}
          {boilerplateParagraphs.length > 1 && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={boilerplateParagraphs[1]}
            />
          )}
        </div>
        <div className="mb-4">
          {boilerplateSubtitles[2] && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={boilerplateSubtitles[2]}
            />
          )}
          {boilerplateParagraphs[2] && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={boilerplateParagraphs[2]}
            />
          )}
          {/* <p style={{ fontSize: 16 }}>
            This copyright agreement shall be governed by and is subject to
            English and Welsh law and parties agree to accept the exclusive
          </p> */}

          <p style={{ fontSize: 16 }}>...</p>

          <p style={{ fontSize: 16 }}>
            I agree to the terms and conditions of this agreement
          </p>

          <div className="questionaire" style={{ marginTop: 10 }}>
            <form>
              <div className="tikbox-form-element">
                <input
                  type="checkbox"
                  id="agree1"
                  checked={acceptedTerms['agree1']}
                  onChange={acceptAgreementTerms}
                  name="agree1"
                  style={{ width: 25 }}
                />
                <label htmlFor="agree1">
                  {user.name}, {moment().format('YYYY-MM-DD')}
                </label>
              </div>
              <div className="tikbox-form-element">
                <input
                  type="checkbox"
                  id="agree2"
                  onChange={acceptAgreementTerms}
                  checked={acceptedTerms['agree2']}
                  name="agree2"
                  style={{ width: 25 }}
                />
                <label htmlFor="agree2">
                  [name of licensee], [address of licensee], [signature of
                  licensee]
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewContent
