import {
  GET_AGREEMENTS,
  GET_AGREEMENTS_LOADING,
  UPDATE_AGREEMENT,
  UPDATE_AGREEMENT_LOADING,
} from '../../redux/actions/Types'

const initialState = {
  data: null,
  loading: false,
}

export default function AgreementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AGREEMENTS:
      return {
        ...state,
        data: action.payload,
      }
    case UPDATE_AGREEMENT: {
      const currStateData = state.data
      const newStateData = currStateData.map((item) =>
        item._id === action.payload._id ? action.payload : item,
      )
      return {
        ...state,
        data: newStateData,
      }
    }
    case GET_AGREEMENTS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case UPDATE_AGREEMENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
