import { GET_TASKS, GET_TASKS_LOADING } from '../actions/Types'

const initialState = {
  data: [],
  loading: false,
}

export default function TaskReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        data: action.payload,
      }
    case GET_TASKS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
