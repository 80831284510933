export const SoundPreviewIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#F9F9F9" />
      <path
        d="M10.5 20C10.5 15.5217 10.5 13.2825 11.8912 11.8912C13.2825 10.5 15.5217 10.5 20 10.5C24.4783 10.5 26.7175 10.5 28.1088 11.8912C29.5 13.2825 29.5 15.5217 29.5 20C29.5 24.4783 29.5 26.7175 28.1088 28.1088C26.7175 29.5 24.4783 29.5 20 29.5C15.5217 29.5 13.2825 29.5 11.8912 28.1088C10.5 26.7175 10.5 24.4783 10.5 20Z"
        stroke="#383838"
        stroke-width="1.5"
      />
      <path
        d="M21 22.5C21 23.8807 19.8807 25 18.5 25C17.1193 25 16 23.8807 16 22.5C16 21.1193 17.1193 20 18.5 20C19.8807 20 21 21.1193 21 22.5ZM21 22.5V15C21.3333 15.5 21.6 17.6 24 18"
        stroke="#383838"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
