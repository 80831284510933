import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import { Footer } from '../main/footer/Footer'
import LandingPageSections from './Sections'
import { Footer } from '../../../components/footer/Footer'

class LandingPageNew extends Component {
  render() {
    return (
      <>
        <LandingPageSections />
        <Footer />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

// export default LandingPageNew
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LandingPageNew))
