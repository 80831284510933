import { useState } from 'react'
import OneRatio from '../../../assets/images/landing-page/tikbox.io.png'
import OneRatioMobile from '../../../assets/images/landing-page/tikbox.io.png'
import MultipleRatios from '../../../assets/images/landing-page/license_tikbox.io.png'
import MultipleRatiosMobile from '../../../assets/images/landing-page/license_tikbox.io.png'
import WithAI from '../../../assets/images/landing-page/who_real.png'
import WithoutAI from '../../../assets/images/landing-page/who_fake.png'
import TestimonialLeft from '../../../assets/images/landing-page/testimonial_left.png'
import TestimonialRight from '../../../assets/images/landing-page/testimonial_right.png'
import PersonOne from '../../../assets/images/landing-page/person_1.png'
import PersonTwo from '../../../assets/images/landing-page/person_2.png'
import PersonThree from '../../../assets/images/landing-page/person_3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGavel,
  faShieldAlt,
  // faBuilding,
} from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'

const testimonials = [
  {
    author: 'Orsolya',
    message: '...',
  },
  {
    author: 'Steve',
    message: '...',
  },
  {
    author: 'Markus',
    message: '...',
  },
  // {
  //  author: 'Tobi Hashima',
  // message:
  // 'Qs far as I know, all the housing made by Lucy Properti has a very strategic location, close to the center of the Government, making it easier for us to carry out activities',
  //},
  //{
  // author: 'Morris Davies',
  //  message:
  //  'Rs far as I know, all the housing made by Lucy Properti has a very strategic location, close to the center of the Government, making it easier for us to carry out activities',
  // },
]

const Services = ({ setIsSignUpOpen }) => {
  const [activeTestimonial, setActiveTestimonial] = useState(2)
  const changeTestimonial = (value) => {
    setActiveTestimonial(value)
  }
  return (
    <section className="tikbox-services">
      <div className="tikbox-container">
        <div className="tikbox-content animate__animated animate__zoomIn">
          <FontAwesomeIcon color="#01C67F" size="3x" icon={faGavel} />

          <h3>Simple Copyright Contract Builder </h3>
          <p>
            Available to all creators. Our custom build tools, template
            agreements, and auto-invoicing enables you to establish copyrights,
            add provenance, and monetize your assets with ease.{' '}
          </p>
        </div>
        <div className="tikbox-content animate__animated animate__zoomIn">
          <FontAwesomeIcon color="#01C67F" size="3x" icon={faShieldAlt} />
          <h3>Enterprise Accounts for In-house Legal</h3>
          <p>
            In-house legal teams can create custom agreements or integrate
            existing ones into the contract builder, ensuring colleagues in
            various functions have access to appropriate contractual
            arrangements.
          </p>
        </div>
        <div className="tikbox-content animate__animated animate__zoomIn">
          <FontAwesomeIcon color="#01C67F" size="3x" icon={faBuilding} />

          <h3>Secure Copyright and Prevenance</h3>
          <p>
            Our multi-layered encryption, ensures the integrity of your
            copyright & provenance metadata & labels. This safeguards your data
            against manipulation & secures your licensing revenue streams.
          </p>
        </div>
      </div>
      <div className="tikbox-services__title">
        <h2>Features</h2>
      </div>

      <div className="tikbox-row walkthrough">
        <div className="tikbox-col-6 image">
          <div className="tikbox-content animate__animated animate__fadeIn">
            <img className="desktop" src={MultipleRatios} alt="multiple" />
            <img className="mobile" src={MultipleRatiosMobile} alt="multiple" />
          </div>
        </div>
        <div className="tikbox-col-6 content">
          <div className="tikbox-content">
            <h2>Own your creativity, monetize your talent</h2>
            <p>
              TikBox is pioneering an online ecosystem tailored for digital
              content creators to assert copyrights, embed provenance details,
              and unlock new revenue streams from their online assets. Our
              platform is designed to seamlessly integrate copyright and
              provenance-related metadata into your digital creations,
              safeguarding them across the internet.
            </p>
            <button onClick={() => setIsSignUpOpen(true)}>
              START LICENSING FOR FREE
            </button>
          </div>
        </div>
      </div>

      <div className="tikbox-row walkthrough">
        <div className="tikbox-col-6 content second">
          <div className="tikbox-content">
            <h2>Compliance and risk management </h2>
            <p>
              With multi-billion dollar copyright infringement cases under way
              in the US and the recent EU AI Act, compliance became a minefield.
              TikBox supports companies looking to comply, by maintaining a
              secure database with extensively labelled content for model
              training, legally aquiring data to ensure exisiting copyright laws
              are respected, and labelling Generative AI content to clearly
              identify it as fake.
            </p>
            <button onClick={() => setIsSignUpOpen(true)}>
              GET COMPLIANT DATA
            </button>
          </div>
        </div>
        <div className="tikbox-col-6 image">
          <div className="tikbox-content animate__animated animate__fadeIn">
            <img className="desktop" src={OneRatio} alt="multiple" />
            <img className="mobile" src={OneRatioMobile} alt="multiple" />
          </div>
        </div>
      </div>

      <div className="tikbox-row tikbox-tracking">
        <div className="tikbox-col-6 images">
          <div className="tikbox-card">
            <div className="tikbox-image animate__animated animate__fadeIn">
              <h3>
                Authentic Photo <br></br>BBC/Ray Burmiston
              </h3>
              <img src={WithAI} alt="Authentic copyrighted photo" />
            </div>
            <div className="tikbox-image animate__animated animate__fadeIn">
              <h3>
                Deepfake Photo <br></br> Midjourney
              </h3>
              <img
                src={WithoutAI}
                alt="Deepfake Photo generated by Midjouney"
              />
            </div>
          </div>
        </div>
        <div className="tikbox-col-6 content">
          <div className="tikbox-content">
            <h2>Distinguish between real and fake content</h2>
            <p>
              It doesn't take much to create a deepfake of some of the most
              recognisable faces and brands. Midjourney prompt for deepfake
              image on the left: "Image from the tv programme Dr Who"
            </p>
            <p>
              Leveraging the emerging C2PA standard, TikBox has added an
              enhanced layer of security to embed provenance labelling into your
              assets, making it easier to distinguish between real and fake
              content. With C2PA collaborating with Meta, Google, LinkedIn,
              X/Twitter, and TikTok, our product is uniquely suited to show
              labelled content on social media.
            </p>
            <button onClick={() => setIsSignUpOpen(true)}>
              PROTECT AGAINST DEEPFAKES
            </button>
          </div>
        </div>
      </div>

      <div className="tikbox-row partnership">
        <div className="tikbox-col-6 texts">
          <div className="tikbox-content">
            <h2>PARTNERSHIPS</h2>
            <h3>We love creative talent</h3>

            <p>
              Some of the best creative talent uses TikBox to share their
              content with a wider audience uninhibited. Why not join the
              community?!
            </p>

            {/* <div className="tikbox-row partners-logos">
              <img className="img-1" src={Netflix} alt="netflix" />
              <img className="img-2" src={Drawkit} alt="drawkit" />
            </div>
            <div className="tikbox-row partners-logos">
              <img className="img-1" src={Figma} alt="figma" />
              <img className="img-2" src={Stripe} alt="stripe" />
            </div> */}
          </div>
        </div>
        <div className="tikbox-col-6 bg-img">
          <div className="tikbox-content"></div>
        </div>
      </div>

      <div className="dark-bg">
        <div className="tikbox-content">
          <h2>Made by creators for creators.</h2>
          <p>
            Innovation is often messy. Great leaps arrive bundled up with new
            challenges rising in their wake. Generative AI might be turning into
            a cherished creative or efficiency tool for many, yet the rise of
            deepfakes, unathorised use of work and compliance with intricate
            legislation creates threats, risks and losses for many. Here at
            TikBox we believe in the potential of human ingenuity as well as in
            lawful constraints: we offer patent-pending legal frameworks that
            embed trust directly into your digital assets.{' '}
          </p>
          <button onClick={() => setIsSignUpOpen(true)}>TRY FOR FREE </button>
        </div>
      </div>

      {/* <div className="testimonial">
        <img
          className="testimonial-bg-img left"
          src={TestimonialLeft}
          alt="testimonial-left"
        />
        <img
          className="testimonial-bg-img right"
          src={TestimonialRight}
          alt="testimonial-right"
        />
        <div className="tikbox-content">
          <h2>TESTIMONIALS</h2>
          <h3>Championing Asset Owners</h3>
          <p>{testimonials[activeTestimonial]?.message}</p>
          <div className="photos animate__animated animate__fadeInUp">
            <div
              className={`photo${activeTestimonial === 0 ? ' active' : ''}`}
              onClick={() => changeTestimonial(0)}>
              <img src={PersonOne} alt="person" />
            </div>
            <div
              className={`photo${activeTestimonial === 1 ? ' active' : ''}`}
              onClick={() => changeTestimonial(1)}>
              <img src={PersonTwo} alt="person" />
            </div>
            <div
              className={`photo${activeTestimonial === 2 ? ' active' : ''}`}
              onClick={() => changeTestimonial(2)}>
              <img src={PersonThree} alt="person" />
            </div>
          </div>
          <p className="author-name">
            {testimonials[activeTestimonial]?.author}
          </p>
        </div>
      </div> */}
    </section>
  )
}

export default Services
