export const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5111 4.15199L14.6792 2.98386C15.3243 2.33871 16.3703 2.33871 17.0155 2.98386C17.6606 3.629 17.6606 4.67498 17.0155 5.32012L15.8473 6.48826M13.5111 4.15199L9.14952 8.51358C8.27846 9.38466 7.8429 9.82016 7.54632 10.3509C7.24974 10.8817 6.95135 12.1349 6.66602 13.3333C7.86442 13.048 9.11768 12.7496 9.64843 12.453C10.1792 12.1564 10.6147 11.7209 11.4858 10.8498L15.8473 6.48826M13.5111 4.15199L15.8473 6.48826"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 10C17.5 13.5355 17.5 15.3033 16.4017 16.4017C15.3033 17.5 13.5355 17.5 10 17.5C6.46447 17.5 4.6967 17.5 3.59835 16.4017C2.5 15.3033 2.5 13.5355 2.5 10C2.5 6.46447 2.5 4.6967 3.59835 3.59835C4.6967 2.5 6.46447 2.5 10 2.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  )
}
