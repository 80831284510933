import AppModal from './AppModal'
import UserProfileModal from './UserProfileModal'

export const AccountModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title} hideHeader={true}>
      <UserProfileModal />
    </AppModal>
  )
}
