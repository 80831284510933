const SelectRadio = ({ selected, disabled, title, ...props }) => {
  return (
    <div
      className={`tikbox-select-radio${selected ? ' selected' : ''}${
        disabled ? ' disabled' : ''
      }`}
      {...props}>
      <div className="radio"></div>
      <span>{title}</span>
    </div>
  )
}

export default SelectRadio
