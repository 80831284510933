import { useSelector } from 'react-redux'
import { AgreementContentType, AgreementKeys } from '../../../constants'
import { AgreementItem } from './AgreementItem'
import Spinner from '../../../components/common/spinner/Spinner'
import { useEffect, useState } from 'react'
import { DropdownIcon } from '../../../components/common/icons/DropdownIcon'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const RoyaltyContent = ({
  data,
  addAgreement,
  removeAgreement,
  selectedAgreements,
  editingAgreement,
  setEditAgreement,
  agreementContent,
  setAgreementContent,
  cancelEditAgreement,
  saveUpdatedAgreement,
}) => {
  const {
    agreements: { loading },
    auth: { user },
  } = useSelector((state) => state)
  const [showFirstContent, setShowFirstContent] = useState(true)
  const [showSecondContent, setShowSecondContent] = useState(true)

  const royalLengthSelection = selectedAgreements[AgreementKeys.RoyaltyLength]
  const royalValueSelection = selectedAgreements[AgreementKeys.RoyaltyValue]
  const royaltyAcknowledgementSelection =
    selectedAgreements[AgreementKeys.RoyaltyAcknowledgement]

  const changeInput = (e) => {
    const { name, value } = e.target
    addAgreement(
      name,
      // name === AgreementKeys.RoyaltyLength ? Number(value) : value,
      value,
    )
  }
  const titles = data.filter(
    (x) => x.contentType === AgreementContentType.TITLE,
  )
  const inputs = data
    .filter((x) => x.contentType === AgreementContentType.INPUT)
    .sort((a, b) => (a.code > b.code ? 1 : -1))
  const radios = data.filter(
    (x) => x.contentType === AgreementContentType.RADIO,
  )
  const spans = data.filter((x) => x.contentType === AgreementContentType.SPAN)

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'royalty',
      userId: user?.id,
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="assignment-content">
      <DropdownIcon
        className={`dropdown${showFirstContent ? ' open' : ''}`}
        onClick={() => setShowFirstContent(!showFirstContent)}
      />
      {titles.map((title) => (
        <AgreementItem
          editingAgreement={editingAgreement}
          setEditAgreement={setEditAgreement}
          cancelEditAgreement={cancelEditAgreement}
          saveUpdatedAgreement={saveUpdatedAgreement}
          agreementContent={agreementContent}
          setAgreementContent={setAgreementContent}
          agreement={title}
        />
      ))}
      <div className="questionaire">
        <form>
          {showFirstContent && inputs.length && (
            <div className="tikbox-form-element" style={{}}>
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={{
                  ...inputs[0],
                  contentType: AgreementContentType.LABEL,
                }}
                htmlFor="royalty"
              />
              <input
                style={{ height: 40, marginLeft: 10 }}
                onChange={changeInput}
                className="royalty"
                type="number"
                name="royaltyValue"
                id="royalty"
                value={royalValueSelection}
              />
            </div>
          )}
          {showFirstContent && (
            <div className="tikbox-form-element">
              <label className="length" htmlFor="1">
                Length
              </label>
              <div className="length-inputs">
                {radios.map((radio) => {
                  const isChecked = royalLengthSelection === radio._id
                  return (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={radio}
                      isChecked={isChecked}
                      addAgreement={addAgreement}
                      removeAgreement={removeAgreement}
                    />
                  )
                })}
              </div>
            </div>
          )}
          {inputs.length > 1 && (
            <div
              className="tikbox-form-element"
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}>
              <DropdownIcon
                style={{ top: 20 }}
                className={`dropdown${showSecondContent ? ' open' : ''}`}
                onClick={() => setShowSecondContent(!showSecondContent)}
              />
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={{
                  ...inputs[1],
                  contentType: AgreementContentType.PARAGRAPH,
                }}
              />
              {showSecondContent && (
                <input
                  style={{ height: 40, minWidth: 400, width: '100%' }}
                  onChange={changeInput}
                  className="royalty"
                  name="royaltyAcknowledgement"
                  id="royalty"
                  value={royaltyAcknowledgementSelection}
                />
              )}
              {showSecondContent && spans.length && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={spans[0]}
                />
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default RoyaltyContent
