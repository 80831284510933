export const SoundMultipleUploadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 13C20 16.7712 20 18.6569 18.7552 19.8284C17.5104 21 15.5069 21 11.5 21H10.7273C7.46607 21 5.83546 21 4.70307 20.2022C4.37862 19.9736 4.09058 19.7025 3.8477 19.3971C3 18.3313 3 16.7966 3 13.7273V11.1818C3 8.21865 3 4.68331 3.46894 3.5C4.22281 1.59767 6.17672 1.00001 7.83836 1C9.5 1 10.6698 1 13.8182 1C15.6173 1 16.5168 1 17.2352 1.2522C18.3902 1.65765 19.3012 2.5151 19.732 3.60214C20 4.27832 20 5.12494 20 6.81818V13Z"
        fill="url(#paint0_linear_2020_2692)"
      />
      <path
        d="M20.4125 21.4643L20.4125 21.4643C19.8994 21.9472 19.2078 22.2174 18.1006 22.3575C16.982 22.4991 15.5168 22.5 13.5 22.5H12.7273C11.0859 22.5 9.8936 22.4994 8.9687 22.403C8.05177 22.3074 7.45789 22.1224 6.99104 21.7935C6.70483 21.5918 6.45176 21.3534 6.23903 21.0859L6.23902 21.0859C5.89586 20.6544 5.70288 20.1079 5.60254 19.2551C5.50081 18.3905 5.5 17.2741 5.5 15.7273V13.1818C5.5 11.6976 5.50008 10.084 5.55818 8.69434C5.58722 7.99964 5.63048 7.36872 5.6942 6.84295C5.75914 6.30715 5.84133 5.91748 5.93377 5.68421L5.93377 5.68421C6.57466 4.06698 8.25649 3.50001 9.83836 3.5L15.8182 3.5C17.6596 3.5 18.4519 3.50714 19.0696 3.72397L19.0696 3.72398C20.0952 4.084 20.8925 4.84101 21.2672 5.78635L21.2672 5.78637C21.3741 6.05602 21.4357 6.37858 21.4676 6.86167C21.4997 7.34924 21.5 7.96497 21.5 8.81818V15C21.5 16.9006 21.4988 18.2727 21.3494 19.3184C21.2026 20.3465 20.9213 20.9854 20.4125 21.4643Z"
        fill="url(#paint1_linear_2020_2692)"
        stroke="white"
      />
      <path
        d="M16.773 8.08162C16.7092 8.03813 16.6357 8.01104 16.5591 8.00273C16.4824 7.99442 16.4049 8.00513 16.3333 8.03394L11.6052 9.94129C11.518 9.97632 11.4431 10.0368 11.39 10.1149C11.337 10.193 11.3082 10.2853 11.3073 10.38V14.4045C11.0882 14.3297 10.8578 14.2942 10.6265 14.2996C9.71395 14.2996 9 14.8909 9 15.649C9 16.4072 9.71395 16.9985 10.6265 16.9985C10.8982 17.01 11.1686 16.9558 11.4154 16.8405C11.6621 16.7252 11.8779 16.5521 12.0449 16.3357C12.1126 16.2942 12.1688 16.2362 12.2085 16.167C12.2481 16.0979 12.2699 16.0198 12.2719 15.9399V12.5353L16.0544 11.0714V13.4556C15.8295 13.3769 15.5925 13.3398 15.3546 13.3459C14.4421 13.3459 13.7281 13.9372 13.7281 14.6954C13.7281 15.4535 14.4421 16.0448 15.3546 16.0448C15.6263 16.0563 15.8968 16.0021 16.1435 15.8868C16.3902 15.7715 16.6061 15.5985 16.773 15.382C16.8407 15.3405 16.897 15.2825 16.9366 15.2133C16.9763 15.1442 16.9981 15.0661 17 14.9862V8.47263C16.9973 8.39358 16.9751 8.31646 16.9355 8.2482C16.8959 8.17995 16.8401 8.1227 16.773 8.08162Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2020_2692"
          x1="11.048"
          y1="2.42492"
          x2="11.048"
          y2="20.9999"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#B0B8FF" />
          <stop offset="1" stop-color="#8D98F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2020_2692"
          x1="13.048"
          y1="4.42492"
          x2="13.048"
          y2="22.9999"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#B0B8FF" />
          <stop offset="1" stop-color="#8D98F8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
