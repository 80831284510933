import React, { useState, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AppModal from './AppModal'
import ShareAppModal from './ShareAppModal'

const ShareModal = ({ open, onClose, title }) => {
  const copyRef = useRef()

  const [copy, setCopy] = useState(false)
  const onCopy = () => {
    setCopy(true)
  }

  const onCloseShare = () => {
    setCopy(false)
    onClose()
  }

  const ShareText =
    'I’ve been using Tikbox to create vertical, square, and landscape videos with the click of a few buttons. You too can make between 1-4 different sized videos for free, try it for yourself here: www.tikbox.com'

  return (
    <AppModal open={open} onClose={onCloseShare} title={title} hideHeader>
      <>
        <p className="sub-title">Share Tikbox (Android, iOS, Web)</p>
        <div className="shareApp">
          <ShareAppModal
            closeModal={onCloseShare}
            copyRef={copyRef}
            ShareText={ShareText}
          />

          <CopyToClipboard onCopy={onCopy} text={ShareText}>
            <button ref={copyRef} style={{ height: 0, visibility: 'hidden' }} />
          </CopyToClipboard>
          {copy && (
            <p className="copied" style={{ textAlign: 'center' }}>
              <strong>Copied</strong>
            </p>
          )}
        </div>
      </>
    </AppModal>
  )
}

export default ShareModal
