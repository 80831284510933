import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import AuthReducer from './AuthReducer'
import AssetReducer from './AssetReducer'
import AgreementReducer from './AgreementReducer'
import UserAgreementReducer from './UserAgreementReducer'
import NavigationReducer from './NavigationReducer'
import AgreementTemplateReducer from './AgreementTemplateReducer'
import TaskReducer from './TaskReducer'

const persistConfig = {
  key: 'data',
  storage,
}
export default combineReducers({
  auth: persistReducer(persistConfig, AuthReducer),
  assets: AssetReducer,
  agreements: AgreementReducer,
  userAgreements: UserAgreementReducer,
  agreementTemplates: AgreementTemplateReducer,
  tasks: TaskReducer,
  navigations: NavigationReducer,
})
