import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { logoutUser } from '../../redux/actions/AuthActions'
// Routers
import { LANDING } from '../../constants/Routes'

const AdminRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const isAuthenticated = auth.isAuthenticated === true
      const isConfirmed = auth?.user?.confirmed
      const isActive = auth?.user?.active
      const isAdmin = auth?.user?.isAdmin

      if (!isAdmin) {
        return <Redirect to={LANDING} />
      }

      if (isAuthenticated && isConfirmed && isActive) {
        return <Component {...props} />
      }

      if (isAuthenticated && (!isConfirmed || !isActive)) {
        return (
          <div>
            <Component {...props} />
            <Modal
              size="lg"
              onClose={() => {
                rest.logoutUser()
                return false
              }}
              isOpen={true}
              isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {!isConfirmed
                    ? 'Thank you for registering'
                    : 'Account is not active'}
                  , {_.first(_.split(auth?.user?.name, ' '))}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {!isConfirmed
                    ? `Please confirm your email address. Check your junkmail, just
                  in case!`
                    : 'Please contact support. Your account has been disabled!'}
                </ModalBody>
              </ModalContent>
            </Modal>
          </div>
        )
      }
      return <Redirect to={LANDING} />
    }}
  />
)

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute)
