import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import Spinner from '../../../components/common/spinner/Spinner'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import { UploadCloudIcon } from '../../../components/common/icons/UploadCloudIcon'
import { EmptyDocumentIcon } from '../../../components/common/icons/EmptyDocumentIcon'
import LibraryAssetUploader from '../../../services/library-assets/LibraryAssetUploader'
import * as UploadServices from '../../../services/UploadServices'
import { toast } from 'react-toastify'
import moment from 'moment'
import FileUtils from '../../../utils/FileUtils'
import { VerifyLoaderIcon } from '../../../components/common/icons/VerifyLoaderIcon'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import TikBoxModal from '../../../components/common/modal/Modal'

const FILE_UPLOAD_SIZE_LIMIT = 2123138000

const VerifyContent = () => {
  const location = useLocation()
  const {
    auth: { user },
  } = useSelector((state) => state)
  const [uploading, setUploading] = useState(false)
  const [assetReference, setAssetReference] = useState('')
  const [assetAgreementData, setAssetAgreementData] = useState()
  const [openAgreementModal, setOpenAgreementModal] = useState(false)

  const toggleAgreementModal = (value) => {
    setOpenAgreementModal(value)
  }
  const onDrop = (files) => {
    triggerGoogleAnalyticsEvent('uploading_verify_asset', {
      userId: user?.id,
    })
    verifyMedia(files[0])
  }

  const getAgreementPDFPassedRedf = async (ref) => {
    setUploading(true)
    await getAssetPDF(ref)
    setUploading(false)
  }

  const getAssetPDF = async (ref) => {
    const assetAgreement = await UploadServices.getAssetAgreement(ref)
    setAssetAgreementData(assetAgreement)
    setAssetReference(ref)
  }

  const verifyMedia = async (file) => {
    try {
      setUploading(true)
      setAssetAgreementData()
      // const files = e.target.files
      // if (_.isEmpty(files)) {
      //   return
      // }

      // const file = _.first(files)
      if (file.size > FILE_UPLOAD_SIZE_LIMIT) {
        return toast.error(
          'Whoa! That is a huge file size. It exceeds our max 5 GB limit. Try something smaller.',
        )
      }

      const fileName = file.name

      const fileExt = fileName.substring(fileName.lastIndexOf('.'))
      const fileNameUrl = `${moment().format('x')}${fileExt}`

      const uploadData = await LibraryAssetUploader.saveAsLibraryAsset(
        file,
        fileNameUrl,
        `assets/${FileUtils.getFileType(file.type)}s`,
        'verify',
      )
      const verifyData = await UploadServices.verifyMedia({
        url: uploadData.location,
      })
      await getAssetPDF(verifyData.ref)
      setUploading(false)
    } catch (error) {
      setUploading(false)
      toast.error('Error verifying media')
    }
  }

  const agreeToTerms = async () => {
    try {
      triggerGoogleAnalyticsEvent('agree_to_asset_terms', {
        userId: user?.id,
      })
      setUploading(true)
      await UploadServices.agreeToAgreementTerms(assetReference)
      setUploading(false)
      setAssetReference('')
      setAssetAgreementData()
      toggleAgreementModal(true)

      // Clear query parameters from the URL
      const url = new URL(window.location)
      url.search = ''
      window.history.replaceState({}, '', url)
      // toast.success('Invites have been sent out')
    } catch (err) {
      setUploading(false)
      toast.error('Error verifying media')
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const ref = queryParams.get('verify_ref')
    if (ref) {
      getAgreementPDFPassedRedf(ref)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })

  return (
    <MainPanel>
      <div className="verify-container">
        <div className="verify-container__section">
          <div className="verify-doc">
            <h2 className="title">Verify</h2>
            <div className="verify-upload-preview">
              <div className="verify-upload" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="text-icon">
                  <UploadCloudIcon />
                  <p>
                    {isDragActive
                      ? 'Drop the file here'
                      : 'Upload to verify asset'}
                  </p>
                </div>
                <button>Click to upload</button>
              </div>

              <div className="verify-preview">
                {uploading ? (
                  <Spinner />
                ) : assetAgreementData?.asset?.deletedAt ? (
                  <div>
                    This asset is no longer available to license at the owner's
                    request
                  </div>
                ) : assetAgreementData ? (
                  <iframe
                    title="pdf-agreement"
                    src={assetAgreementData?.userAgreement?.agreementUrl}
                    width="100%"
                    height="100%"></iframe>
                ) : (
                  <>
                    <EmptyDocumentIcon />
                    <p>You haven’t uploaded any documents</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="verify-assets">
            <div className="verify-asset">
              <h4>Assets</h4>
              <div className="asset-empty">
                {' '}
                {uploading ? (
                  <Spinner />
                ) : assetAgreementData &&
                  !assetAgreementData?.asset?.deletedAt ? (
                  <img
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={assetAgreementData?.asset?.fileUrl}
                    alt="asset"
                  />
                ) : (
                  'No assets'
                )}
              </div>
            </div>
          </div>
        </div>
        {!assetAgreementData?.asset?.deletedAt &&
          assetAgreementData?.comment && <p>{assetAgreementData.comment}</p>}
        {!assetAgreementData?.asset?.deletedAt && assetAgreementData && (
          <button onClick={agreeToTerms} className="agree-btn">
            Agree to terms
          </button>
        )}
      </div>
      <TikBoxModal
        open={openAgreementModal}
        maxW="600px"
        toggle={toggleAgreementModal}
        title="Request received">
        <p>
          Thank you for your interest in licensing this asset. An agreement for
          your signature will be emailed to you shortly.
        </p>
      </TikBoxModal>
    </MainPanel>
  )
}

const UserVerify = () => {
  return (
    <div className="tikbox-user-verify">
      <div className="tikbox-user-verify__content">
        <SidePanel />
        <VerifyContent />
      </div>
    </div>
  )
}

export default UserVerify
