import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// Utils
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/SetAuthToken'
// Redux
import store from './redux'
import { logoutUser, setCurrentUser } from './redux/actions/AuthActions'
import { connect } from 'react-redux'
// Routers
import * as ROUTES from './constants/Routes'
// Components
import PrivateRoute from './components/private-route/PrivateRoute'
import AdminRoute from './components/private-route/AdminRoute'
import NotFound from './components/404/404'

import LandingPage from './pages/main/landingpage'
import LandingPageNew from './pages/main/landing-page/LandingPageNew'
import SignUpPage from './components/auth/SignUp'
import SignInPage from './components/auth/SignIn'
import ConfirmUser from './components/auth/ConfirmUser'
import PasswordForgetPage from './components/auth/PasswordForget'
import PasswordReset from './components/auth/PasswordReset'
import HomePage from './pages/user/home/Home'
import Agreement from './pages/user/agreement/Agreement'
import GoogleAnalytics from './GoogleAnalytics'
import Verify from './pages/verify/Verify'
import Assets from './pages/user/assets/Assets'
import InviteMembers from './pages/admin/iinvite-users/InviteMembers'
import UserVerify from './pages/user/verify/UserVerify'
import TemplateAgreement from './pages/user/template-agreement/TemplateAgreement'
import PricingPage from './pages/pricing/Pricing'
import PrivacyPolicy from './pages/terms/PrivacyPolicy'
import TermsConditions from './pages/terms/TermsConditions'
import DMCAPage from './pages/terms/DMCA'

// Check for token to keep user logged in
if (localStorage.jwtTokenCTEditor) {
  // Set auth token header auth
  const token = localStorage.jwtTokenCTEditor
  setAuthToken(token)

  // Decode token and get user info and exp
  const decoded = jwt_decode(token)

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))

  // Check for expired token
  const currentTime = Date.now() / 1000 // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser())
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <GoogleAnalytics /> {GoogleAnalytics}
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPageNew} />
            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route exact path={ROUTES.CONFIRM_USER} component={ConfirmUser} />
            <Route exact path={ROUTES.PRICING} component={PricingPage} />
            <Route
              exact
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />
            <Route
              exact
              path={ROUTES.PASSWORD_RESET}
              component={PasswordReset}
            />
            <Route exact path={ROUTES.VERIFY} component={Verify} />
            <Route
              exact
              path={ROUTES.PRIVACY_POLICY}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={ROUTES.TERMS_CONDITIONS}
              component={TermsConditions}
            />
            <Route exact path={ROUTES.DMCA} component={DMCAPage} />

            <PrivateRoute exact path={ROUTES.HOME} component={HomePage} />
            <PrivateRoute exact path={ROUTES.AGREEMENT} component={Agreement} />
            <PrivateRoute exact path={ROUTES.ASSETS} component={Assets} />
            <PrivateRoute
              exact
              path={ROUTES.USER_TEMPLATE_AGREEMENT}
              component={TemplateAgreement}
            />
            <PrivateRoute
              exact
              path={ROUTES.USER_VERIFY}
              component={UserVerify}
            />
            <AdminRoute
              exact
              path={ROUTES.ADMIN_INVITE_MEMBERS}
              component={InviteMembers}
            />

            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(App)
