import _ from 'lodash'
import * as FileUtils from '../../utils/FileUtils'
import S3BucketUploader from './S3BucketUploader'

class LibraryAssetUploader {
  async saveAsLibraryAsset(
    fileBlob,
    fileUrl,
    assetFolderName,
    authorId,
    additionalSuffix = '',
    manageUpload = false,
    callback,
  ) {
    const fileName = FileUtils.getFilename(fileUrl) + additionalSuffix

    const extension = FileUtils.getFileExtension(fileUrl)
    const updatedFileName = FileUtils.replaceSpacesIntoUnderscores(fileName)

    // asset.blobDuration = await this.getDurationByBlob(fileBlob)
    if (manageUpload) {
      const s3Upload = await S3BucketUploader.manageUploadAssetToS3Bucket(
        assetFolderName,
        authorId,
        fileBlob,
        updatedFileName,
        extension,
        callback,
      )
      return s3Upload
    }
    const s3Upload = await S3BucketUploader.uploadAssetToS3Bucket(
      assetFolderName,
      authorId,
      fileBlob,
      updatedFileName,
      extension,
    )
    return s3Upload
  }
}

export default new LibraryAssetUploader()
