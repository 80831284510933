const NavigationBtns = ({
  prevStep,
  nextStep,
  step,
  acceptedTerms,
  downloadAgreement,
  downloading,
}) => {
  return (
    <div className="agreement-interface-nav-btns">
      <button className="back" onClick={prevStep} disabled={downloading}>
        Back
      </button>
      <button
        className="next"
        onClick={step === 5 ? downloadAgreement : nextStep}
        disabled={(step === 5 && !acceptedTerms.agree1) || downloading}>
        {step === 4 ? 'Review' : step === 5 ? 'Create & Download PDF' : 'Next'}
      </button>
    </div>
  )
}
export default NavigationBtns
