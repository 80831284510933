import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../../assets/images/TIKBOX_logo_Blue.jpg'
import {
  ASSETS,
  HOME,
  ADMIN_INVITE_MEMBERS,
  USER_VERIFY,
  USER_TEMPLATE_AGREEMENT,
} from '../../../constants/Routes'
import './side-panel.scss'
import { HomeIcon } from '../icons/HomeIcon'
import { AssetsIcon } from '../icons/AssetsIcon'
import { TemplateAgreementsIcon } from '../icons/TemplateAgreeementsIcon'
import { VerifyIcon } from '../icons/VerifyIcon'
import { SidebarToggleIcon } from '../icons/SidebarToggleIcon'
import { toggleSidebar } from '../../../redux/actions/NavigationActions'

const SidePanel = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    auth: { user },
    navigations: {
      data: { sidebarOpen },
    },
  } = useSelector((state) => state)

  const toggleSidebarFunc = () => {
    dispatch(toggleSidebar({ sidebarOpen: !sidebarOpen }))
  }

  const isHomeActive = location.pathname === HOME
  const isAssetsActive = location.pathname === ASSETS
  const isAdminInviteUsersActive = location.pathname === ADMIN_INVITE_MEMBERS
  const isVerifyActive = location.pathname === USER_VERIFY
  const isTemplateAgreementActive =
    location.pathname === USER_TEMPLATE_AGREEMENT

  return (
    <div className={`tikbox-sidepanel${!sidebarOpen ? ' minimized' : ''}`}>
      <div className="tikbox-sidepanel__content">
        <div className="tikbox-logo-section">
          <Link to={HOME}>
            <img className="logo" src={Logo} alt="logo" />
          </Link>
          <SidebarToggleIcon onClick={toggleSidebarFunc} />
        </div>
        <div className="tikbox-sidepanel__content-menu">
          <ul className="sidepanel-menu">
            <li className={`menu-item${isHomeActive ? ' active' : ''}`}>
              <Link to={HOME}>
                <HomeIcon active={isHomeActive} />
                <span>Home</span>
              </Link>
            </li>
            <li className={`menu-item${isAssetsActive ? ' active' : ''}`}>
              <Link to={ASSETS}>
                {' '}
                <AssetsIcon active={isAssetsActive} />
                <span>Assets</span>
              </Link>
            </li>
            <li
              className={`menu-item${
                isTemplateAgreementActive ? ' active' : ''
              }`}>
              <Link to={USER_TEMPLATE_AGREEMENT}>
                {' '}
                <TemplateAgreementsIcon active={false} />
                <span>Template Agreements</span>
              </Link>
            </li>
            <li className={`menu-item${isVerifyActive ? ' active' : ''}`}>
              <Link to={USER_VERIFY}>
                {' '}
                <VerifyIcon active={isVerifyActive} />
                <span>Verify</span>
              </Link>
            </li>
            {user?.isAdmin && (
              <li
                className={`menu-item${
                  isAdminInviteUsersActive ? ' active' : ''
                }`}>
                <Link to={ADMIN_INVITE_MEMBERS}>
                  <TemplateAgreementsIcon active={false} />
                  <span>Invite members</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SidePanel
