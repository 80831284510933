import { useState } from 'react'
import './mailchimp.css'

const CustomForm = () => {
  const [email, setEmail] = useState('')
  const changeEmail = (e) => {
    setEmail(e.target.value)
  }

  return (
    <form
      className="validate mc__form"
      action={`https://tikbox.us19.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&amp;id=${process.env.REACT_APP_MAILCHIMP_ID}`}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate>
      <div className="mc__field-container">
        <label>Email*</label>
        <input
          label="Email"
          onChange={changeEmail}
          type="email"
          name="EMAIL"
          value={email}
          id="mce-EMAIL"
          className="required email"
          placeholder="Your email address"
          required
        />
      </div>
      <p className="mc__title">
        By submitting your information, you're giving us permission to email
        you. You may unsubscribe at any time.
      </p>
      <button type="submit" className="subscribe-button">
        SIGN UP
      </button>
    </form>
  )
}

const MailchimpFormContainer = () => {
  return (
    <div className="mc__form-container">
      <CustomForm />
    </div>
  )
}

export default MailchimpFormContainer
