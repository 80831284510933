import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import { resetSigningAssets } from '../../../redux/actions/AssetActions'
import { DropdownIcon } from '../../../components/common/icons/DropdownIcon'
import { PlusIcon } from '../../../components/common/icons/PlusIcon'
import AssetsUploadSection from '../../../components/common/assets-upload-section/AssetsUploadSection'
import TikBoxModal from '../../../components/common/modal/Modal'
import {
  getAgreementTemplates,
  saveAgreementTemplate,
} from '../../../redux/actions/AgreementTemplateActions'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const TemplateAgreementContent = () => {
  const dispatch = useDispatch()
  const [openSelectBox, setOpenSelectBox] = useState(false)
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false)
  const [selectedTemplateAgreement, setSelectedTemplateAgreement] = useState()
  const [templateName, setTemplateName] = useState('')
  const [useWhen, setUseWhen] = useState('')
  const {
    agreementTemplates: { data, loading },
    auth: { user },
  } = useSelector((state) => state)

  const selectTemplate = (value) => {
    triggerGoogleAnalyticsEvent('select_template_agreement', {
      value,
      userId: user?.id,
    })
    setSelectedTemplateAgreement(value)
  }

  const toggleAddTemplateModal = (value) => {
    triggerGoogleAnalyticsEvent('toggle_add_agreement_template', {
      value,
      userId: user?.id,
    })
    setOpenAddTemplateModal(value)
  }

  const submitForm = (when) => {
    triggerGoogleAnalyticsEvent('save_agreement_template', {
      value: when,
      userId: user?.id,
    })
    setUseWhen(when)
    handleSaveAgreementTemplate(
      { name: templateName },
      when === 'now' ? setSelectedTemplateAgreement : undefined,
    )
  }

  const handleSaveAgreementTemplate = async (data, callback) => {
    await dispatch(saveAgreementTemplate(data, callback))
    setOpenAddTemplateModal(false)
  }

  const handleGetAgreementTemplates = async () => {
    await dispatch(getAgreementTemplates())
  }

  const resetAnySigningAsset = async () => {
    await dispatch(resetSigningAssets())
  }

  const toggleSelectBox = (val) => {
    setOpenSelectBox(val)
  }

  useEffect(() => {
    window.addEventListener('click', (e) => {
      let hasDropdownClass = false
      e.target.classList.forEach((className) => {
        if (
          className === 'status-text' ||
          className === 'status-text-content' ||
          className === 'dropdown-container' ||
          className === 'dropdown-toggler'
        ) {
          hasDropdownClass = true
        }
      })
      if (!hasDropdownClass) {
        toggleSelectBox(false)
      }
    })
    return () => {
      window.removeEventListener('click', () => {
        toggleSelectBox(false)
      })
    }
  }, [])

  useEffect(() => {
    handleGetAgreementTemplates()
    resetAnySigningAsset()
  }, [])

  return (
    <MainPanel>
      <div className="template-agreement-container">
        <div className="title">
          <h2>Template Agreement</h2>
        </div>
        <div className="template-agreement-container__section">
          <div className="agreement-templates__list">
            <h4>Add New Template Agreement</h4>
            <p>Select Template</p>
            <div className="agreement-templates-select">
              <div
                className="dropdown-container tikbox-center"
                onClick={() => toggleSelectBox(true)}>
                <div className="status-text">
                  <span className="status-text-content">
                    {selectedTemplateAgreement
                      ? data.find(
                          (template) =>
                            template._id === selectedTemplateAgreement,
                        )?.templateName
                      : 'Select'}
                  </span>
                  <DropdownIcon
                    onClick={() => toggleSelectBox(true)}
                    className="dropdown-toggler"
                  />
                </div>
                <div
                  className={`dropdown-container__dropdown${
                    openSelectBox ? ' open' : ''
                  }`}>
                  <ul>
                    {data.map((template) => {
                      return (
                        <li onClick={() => selectTemplate(template?._id)}>
                          {template.templateName}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div
                className="add-icon"
                onClick={() => toggleAddTemplateModal(true)}>
                <PlusIcon />
              </div>
            </div>
          </div>
          <div
            className={`agreement-templates__upload${
              selectedTemplateAgreement ? ' active' : ''
            }`}>
            <AssetsUploadSection template={selectedTemplateAgreement} />
          </div>
        </div>
      </div>
      <TikBoxModal
        open={openAddTemplateModal}
        toggle={toggleAddTemplateModal}
        title="Add New Template Agreement">
        <div className="template-agreement-form">
          <div className="input-container">
            <p className="input-container__label">Template Name</p>
            <input
              value={templateName}
              onChange={(e) => {
                setTemplateName(e.target.value)
              }}
              type="text"
              placeholder="Template Name"
            />
          </div>

          <div className="action-btns">
            <button
              className="use-later-btn"
              onClick={() => submitForm('later')}>
              {loading && useWhen === 'later' ? 'Loading...' : 'Use Later'}
            </button>
            <button className="use-now-btn" onClick={() => submitForm('now')}>
              {loading && useWhen === 'now' ? 'Loading...' : 'Use Now'}
            </button>
          </div>
        </div>
      </TikBoxModal>
    </MainPanel>
  )
}

const TemplateAgreement = () => {
  return (
    <div className="tikbox-template-agreement">
      <div className="tikbox-template-agreement__content">
        <SidePanel />
        <TemplateAgreementContent />
      </div>
    </div>
  )
}

export default TemplateAgreement
