import _ from 'lodash'
import AWS from 'aws-sdk'
import S3 from '../../components/react-aws-s3'

// Configure the AWS SDK with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_API_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
})

class S3BucketUploader {
  async uploadAssetToS3Bucket(
    assetFolderName,
    authorId,
    file,
    updatedFileName,
    extension,
  ) {
    const config = {
      bucketName: `${process.env.REACT_APP_BUCKET_NAME}`,
      dirName: `${assetFolderName}/${authorId}`,
      region: `${process.env.REACT_APP_REGION}`,
      accessKeyId: process.env.REACT_APP_API_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    }

    const ReactS3Client = new S3(config)
    const data = await ReactS3Client.uploadFile(
      file,
      `${updatedFileName}`,
      `${extension}`,
    )
    return data
  }
  async manageUploadAssetToS3Bucket(
    assetFolderName,
    authorId,
    file,
    updatedFileName,
    extension,
    callback,
  ) {
    return new Promise((resolve, reject) => {
      const s3 = new AWS.S3({
        region: `${process.env.REACT_APP_REGION}`,
      })
      const params = {
        Bucket: `${process.env.REACT_APP_BUCKET_NAME}`,
        // Key: file.name,
        Key: `${assetFolderName}/${authorId}/${updatedFileName}.${extension}`,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read',
      }

      const uploader = new AWS.S3.ManagedUpload({
        service: s3,
        params,
        // partSize: 1000, // Optional, defaults to 5MB
        // queueSize: 4, // Optional, defaults to 4
      })

      uploader.on('httpUploadProgress', (progress) => {
        const percentCompleted = Math.round(
          (progress.loaded * 100) / progress.total,
        )
        // if (runCallback) {
        callback && callback(percentCompleted)
        // }
      })
      uploader.send((err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }
}

export default new S3BucketUploader()
