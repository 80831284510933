export const SearchIcon = (props) => {
  return (
    <svg
      {...props}
      style={{ cursor: 'pointer' }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2053_7028)">
        <path
          d="M14.584 14.5833L18.334 18.3333"
          stroke="#848484"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.666 9.16675C16.666 5.02461 13.3082 1.66675 9.16602 1.66675C5.02388 1.66675 1.66602 5.02461 1.66602 9.16675C1.66602 13.3089 5.02388 16.6667 9.16602 16.6667C13.3082 16.6667 16.666 13.3089 16.666 9.16675Z"
          stroke="#848484"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2053_7028">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
